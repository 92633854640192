<template>
  <div class="container mt-5">
    <h1 class="title">Cambiar Contraseña</h1>

    <div class="columns is-centered">
      <div class="column is-5-tablet is-4-desktop is-3-widescreen">
        <form @submit.prevent>
          <b-field label="Contraseña Actual">
            <b-input
              v-model="currentPassword"
              icon="key"
              type="password"
              password-reveal
              required
            >
            </b-input>
          </b-field>
          <b-field label="Nueva Contraseña">
            <b-input
              v-model="newPassword"
              icon="key"
              type="password"
              minlength="8"
              password-reveal
              required
            >
            </b-input>
          </b-field>
          <b-field
            label="Confirmar Nueva Contraseña"
            :type="{ 'is-danger': confirmError }"
            :message="{ 'Las contraseñas no coinciden': confirmError }"
            minlength="8"
            password-reveal
            required
          >
            <b-input
              v-model="newPasswordConfirm"
              icon="key"
              type="password"
              password-reveal
              required
            >
            </b-input>
          </b-field>
          <p class="has-text-centered mb-4">
            <span class="has-text-danger">{{ error }}</span>
          </p>
          <p class="has-text-centered mb-4">
            <span class="has-text-success">{{ successMessage }}</span>
          </p>
          <b-button
            type="is-primary"
            native-type="submit"
            expanded
            :disabled="confirmError"
            @click="submit"
          >
            Cambiar Contraseña
          </b-button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import { routes } from "@/api";

  export default {
    name: "ChangePassword",
    data() {
      return {
        currentPassword: "",
        newPassword: "",
        newPasswordConfirm: "",
        hasError: false,
        error: null,
        successMessage: null,
      };
    },
    computed: {
      confirmError() {
        return this.newPassword !== this.newPasswordConfirm;
      },
    },
    methods: {
      submit() {
        this.error = null;
        this.successMessage = null;
        this.axios
          .post(routes.passwordChange, {
            currentPassword: this.currentPassword,
            newPassword: this.newPassword,
          })
          .then(() => {
            this.currentPassword = "";
            this.newPassword = "";
            this.newPasswordConfirm = "";
            this.successMessage = "Contraseña cambiada correctamente";
          })
          .catch((e) => {
            this.error = e.response.data;
          });
      },
    },
  };
</script>

<style scoped></style>
