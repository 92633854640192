<template>
  <div>
    <b-table
      v-if="pools.length > 0 || loading"
      :data="poolsFormatted"
      :loading="loading"
    >
      <template v-for="column in tableColumns">
        <b-table-column v-slot="props" :key="column.field" v-bind="column">
          {{ props.row[column.field] }}
        </b-table-column>
      </template>
      <b-table-column v-if="enableDeletion" v-slot="props">
        <b-tooltip label="Eliminar Pool" type="is-light">
          <b-button
            type="is-text"
            size="is-small"
            @click="deletePool(props.row.id)"
          >
            <b-icon icon="delete" />
          </b-button>
        </b-tooltip>
      </b-table-column>
      <template slot="empty"><div class="table-empty" /></template>
    </b-table>
    <b-message v-else> No hay resultados para mostrar. </b-message>
  </div>
</template>

<script>
  import { formatDateTime } from "@/utils";
  import { routes } from "@/api";

  export default {
    name: "PoolList",
    props: {
      endpoint: {
        type: String,
        required: true,
      },
      showResults: {
        type: Boolean,
        default: false,
      },
      enableDeletion: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        pools: [],
        loading: false,
        tableColumns: [
          {
            field: "display",
            label: "ID",
            centered: true,
          },
          {
            field: "samples",
            label: "Muestras",
            centered: true,
          },
          {
            field: "creation",
            label: "Fecha de Creación",
            centered: true,
          },
          {
            field: "conclusion",
            label: "Resultado",
            centered: true,
            visible: this.showResults,
          },
        ],
      };
    },
    computed: {
      poolsFormatted() {
        return this.pools.map((x) => ({
          id: x.id,
          display: this.formatId(x.id),
          creation: formatDateTime(x.creation),
          samples: x.samples.join(", "),
          conclusion: x.conclusion ? x.conclusion : "-",
        }));
      },
    },
    created() {
      this.loadPools();
    },
    methods: {
      loadPools() {
        this.loading = true;
        this.axios.get(this.endpoint).then((response) => {
          this.pools = response.data.pools;
          this.loading = false;
        });
      },
      deletePool(id) {
        this.axios
          .post(routes.deletePool.replace(":id", id))
          .then((response) => {
            if (response.data.status === "OK") {
              this.$buefy.toast.open({
                duration: 2000,
                message: "Pool eliminada correctamente",
                type: "is-success",
              });
              this.loadPools();
            }
          });
      },
      formatId(id) {
        return process.env.VITE_POOL_PREFIX + id.toString().padStart(3, "0");
      },
    },
  };
</script>

<style scoped></style>
