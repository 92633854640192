<template>
  <div>
    <h2 class="subtitle">Crear Pools</h2>

    <p class="has-text-grey">
      Esta opción permite crear "pools" de muestras para hacer la amplificación.
      Estas serán integradas en el sistema de forma que el proceso de validación
      se asocie a las muestras correspondientes.
    </p>

    <b-steps
      v-model="currentStep"
      :rounded="false"
      :has-navigation="false"
      class="mt-4"
    >
      <b-step-item step="1" label="Seleccionar Muestras" :clickable="!saved">
        <p class="has-text-grey">
          Las muestras listadas corresponden a las pendientes para PCR que no
          han sido asociadas a ningún pool. Las muestras seleccionadas son las
          que estarán disponibles para crear pools en el paso siguiente.
        </p>
        <b-field grouped position="is-right">
          <b-button
            icon-right="arrow-right"
            type="is-primary"
            :disabled="samples.length === 0"
            @click="goToPoolCreation"
          >
            Siguiente
          </b-button>
        </b-field>
        <sample-list-for-pooling @update-selection="samples = $event" />
      </b-step-item>
      <b-step-item step="2" label="Crear Pools" :clickable="!saved">
        <p class="has-text-grey">
          Es posible crear pools manualmente o de forma automática a través del
          botón "Generar automáticamente" (igualmente es posible editarlas
          posteriormente). El botón para avanzar al siguiente paso será
          habilitado sólo cuando todas las muestras seleccionadas previamente
          hayan sido asignadas a un pool.
        </p>
        <b-field grouped position="is-right">
          <b-button
            icon-right="arrow-right"
            type="is-primary"
            :disabled="samples.length > pools.flat().length"
            @click="currentStep += 1"
          >
            Siguiente
          </b-button>
        </b-field>
        <sample-pools-creator
          :samples="samples"
          :pools.sync="pools"
          @add-pool="addNewPool"
          @delete-pool="removePool"
          @add-to-pool="addToPool"
          @delete-from-pool="removeFromPool"
        />
      </b-step-item>
      <b-step-item step="3" label="Guardar y Descargar">
        <p class="has-text-grey">
          Aquí se muestra una lista con los pools generados. El botón "Guardar"
          registrará los pools en la plataforma y asignará el campo "ID" (que
          deberá usarse al subir los datos de la amplificación). El botón
          "Descargar" será habilitado una vez realizado este proceso.
        </p>
        <new-sample-pools-summary :pools-raw="pools" @saved="saved = true" />
      </b-step-item>
    </b-steps>
  </div>
</template>

<script>
  import SampleListForPooling from "@/components/pools/SampleListForPooling.vue";
  import SamplePoolsCreator from "@/components/pools/SamplePoolsCreator.vue";
  import NewSamplePoolsSummary from "@/components/pools/NewSamplePoolsSummary.vue";

  export default {
    name: "CreateSamplePools",
    components: {
      NewSamplePoolsSummary,
      SamplePoolsCreator,
      SampleListForPooling,
    },
    data() {
      return {
        samples: [],
        pools: [],
        currentStep: 0,
        saved: false,
      };
    },
    methods: {
      goToPoolCreation() {
        this.pools = [];
        this.currentStep = 1;
      },
      addNewPool() {
        this.pools.push([]);
      },
      removePool(idx) {
        this.pools.splice(idx, 1);
      },
      addToPool(poolIdx, sample) {
        this.pools[poolIdx].push(sample);
      },
      removeFromPool(poolIdx, sampleIdx) {
        this.pools[poolIdx].splice(sampleIdx, 1);
      },
    },
  };
</script>

<style scoped></style>
