<template>
  <div class="container mt-5">
    <h1 class="title">Usuarios</h1>

    <div class="has-text-right">
      <b-button
        tag="router-link"
        :to="{ name: 'NewUser' }"
        icon-left="account-plus"
        class="is-primary"
      >
        Nuevo
      </b-button>
    </div>

    <div class="has-text-left">
      <b-switch v-model="enabledOnly" :disabled="loading" :rounded="false">
        Sólo usuarios activos
      </b-switch>
    </div>

    <b-table
      :data="displayedUsers"
      :loading="loading"
      hoverable
      paginated
      :per-page="25"
    >
      <b-table-column
        v-slot="props"
        field="username"
        label="Usuario"
        centered
        sortable
      >
        {{ props.row.username }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="displayName"
        label="Nombre"
        centered
      >
        {{ props.row.displayName }}
      </b-table-column>
      <b-table-column v-slot="props" field="roles" label="Roles" centered>
        <b-tag v-for="role in props.row.roles" :key="role" class="mr-1">
          {{ role }}
        </b-tag>
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="lastLogin"
        label="Últ. Sesión"
        centered
        sortable
      >
        {{ formatDateTime(props.row.lastLogin) }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        :visible="!enabledOnly"
        field="enabled"
        label="Habilitado"
        centered
      >
        <b-icon v-if="props.row.enabled" icon="check" />
        <b-icon v-else icon="close" />
      </b-table-column>
      <b-table-column v-slot="props" cell-class="py-0 px-2">
        <b-button
          type="is-text"
          tag="router-link"
          :to="{ name: 'EditUser', params: { id: props.row.id } }"
        >
          <b-icon icon="account-edit" type="is-primary" />
        </b-button>
      </b-table-column>
      <template #empty><div class="table-empty" /></template>
    </b-table>
  </div>
</template>

<script>
  import { routes } from "@/api";
  import { formatDateTime } from "@/utils";

  export default {
    name: "ListUsers",
    data() {
      return {
        users: [],
        enabledOnly: true,
        loading: false,
      };
    },
    computed: {
      displayedUsers() {
        if (this.enabledOnly) {
          return this.users.filter((x) => x.enabled);
        } else {
          return this.users;
        }
      },
    },
    created() {
      this.loadUsers();
    },
    methods: {
      async loadUsers() {
        this.loading = true;
        try {
          const response = await this.axios.get(routes.getUsers);
          this.users = response.data.results;
        } catch (e) {
          this.$log.error(e);
        }
        this.loading = false;
      },
      formatDateTime(datetime) {
        return formatDateTime(datetime, true);
      },
    },
  };
</script>

<style scoped></style>
