<template>
  <form @submit.prevent>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ title }}</p>
      </header>
      <section class="modal-card-body">
        <p v-if="message !== ''" class="mb-4">
          {{ message }}
        </p>
        <b-field>
          <b-input
            v-model="textLocal"
            :maxlength="textLength"
            type="textarea"
          />
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" @click="$parent.close()">
          Cancelar
        </button>
        <button
          v-if="withDelete"
          class="button is-danger"
          type="button"
          @click="$emit('delete', $parent)"
        >
          Borrar
        </button>
        <button
          class="button"
          :class="confirmClass"
          @click="$emit('save', textLocal, $parent)"
        >
          {{ confirmText }}
        </button>
      </footer>
    </div>
  </form>
</template>

<script>
  export default {
    name: "AnnotationModal",
    props: {
      title: { type: String, required: true },
      message: { type: String, default: "" },
      text: { type: String || null, default: null },
      textLength: { type: Number, default: 128 },
      withDelete: { type: Boolean, default: false },
      confirmText: { type: String, default: "Guardar" },
      confirmClass: { type: String, default: "is-primary" },
    },
    data() {
      return {
        textLocal: this.$props.text,
      };
    },
  };
</script>

<style scoped></style>
