<template>
  <div>
    <chartjs-stacked-barplot
      :chart-data="dailyCasesData"
      title="Muestras procesadas por día"
    />
    <div class="level">
      <div class="level-item">
        <p class="control">
          <b-switch v-model="relative" :rounded="false">
            Valores Relativos
          </b-switch>
        </p>
        <p class="control ml-4">
          <b-tooltip type="is-light" label="Descargar imagen">
            <b-button
              type="is-primary"
              size="is-small"
              outlined
              @click="exportToImage"
            >
              <b-icon icon="download" custom-size="mdi-18px" />
            </b-button>
          </b-tooltip>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import ChartjsStackedBarplot from "@/components/plots/ChartjsStackedBarPlot.vue";

  export default {
    name: "DailyCasesBarplot",
    components: { ChartjsStackedBarplot },
    props: {
      data: {
        required: true,
        type: Object,
      },
    },
    data() {
      return {
        rangeDates: [],
        colors: {
          Positivo: "rgba(255,88,88,0.9)",
          "No Concluyente": "rgba(255,176,85,0.9)",
          Negativo: "rgba(218,218,218,0.9)",
        },
        relative: false,
      };
    },
    computed: {
      relativeData() {
        if (Object.keys(this.data).length === 0) {
          return {};
        }

        const normalize = (x, i) => {
          return ((x / this.data.total[i]) * 100).toFixed(1);
        };
        let normalizedData = Object.keys(this.colors).reduce((o, color) => {
          return { ...o, [color]: this.data[color].map(normalize) };
        }, {});

        return { date: this.data.date, ...normalizedData };
      },
      dailyCasesData() {
        const plotData = this.relative ? this.relativeData : this.data;

        return {
          labels: plotData.date
            ? plotData.date.map((x) =>
                this.$date(x, "YYYY-MM-DD").format("DD-MM-YY")
              )
            : [],
          datasets: Object.keys(this.colors).map((x) => {
            return plotData.Positivo
              ? {
                  label: x,
                  backgroundColor: this.colors[x],
                  data: plotData[x],
                }
              : [];
          }),
          isRelative: this.relative,
        };
      },
    },
    methods: {
      exportToImage() {
        const canvas = document
          .getElementById("stacked-barchart")
          .toDataURL("image/png");
        let link = document.createElement("a");
        link.download = `daily_samples_${this.$date().format(
          "YYYYMMDD_HHmm"
        )}.png`;
        link.href = canvas;
        link.click();
      },
    },
  };
</script>

<style scoped></style>
