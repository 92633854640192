<template>
  <div class="mt-4">
    <div class="field is-grouped is-grouped-right">
      <b-field v-if="!saved">
        <b-button
          :loading="saving"
          icon-left="content-save"
          type="is-primary"
          @click="submitPools"
        >
          Guardar
        </b-button>
      </b-field>
      <b-field>
        <b-button :disabled="!saved" icon-left="download" type="is-primary">
          <download-csv
            :data="poolsFormatted"
            :labels="tableLabels"
            :separator-excel="excelCompatible"
            :name="`pools_${$date().format('YYYYMMDD')}.csv`"
          >
            Descargar lista (.csv)
          </download-csv>
        </b-button>
      </b-field>
    </div>
    <div class="field is-grouped is-grouped-right">
      <b-checkbox v-model="excelCompatible"> Compat. con MS Excel </b-checkbox>
    </div>

    <b-table :data="poolsFormatted">
      <b-table-column v-slot="props" label="#" centered>
        {{ props.index + 1 }}
      </b-table-column>
      <b-table-column v-slot="props" label="ID" centered>
        {{ props.row.id ? props.row.id : "-" }}
      </b-table-column>
      <b-table-column v-slot="props" label="Muestras" centered>
        {{ props.row.samples }}
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
  import { routes } from "@/api";

  export default {
    name: "NewSamplePoolsSummary",
    props: {
      poolsRaw: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        pools: [],
        saving: false,
        saved: false,
        excelCompatible: false,
        tableLabels: {
          id: "ID",
          samples: "Muestras",
        },
      };
    },
    computed: {
      poolsFormatted() {
        return this.pools.map((x) => ({
          id: x.id ? this.formatId(x.id) : null,
          samples: x.samples.join(", "),
        }));
      },
    },
    watch: {
      poolsRaw(value) {
        this.pools = value.map((x) => ({ id: null, samples: x }));
      },
    },
    methods: {
      submitPools() {
        this.saving = true;
        this.axios
          .post(routes.createPools, { pools: this.poolsRaw })
          .then((response) => {
            if (response.data.status === "OK") {
              this.pools = response.data.pools;
              this.saved = true;
              this.$buefy.toast.open({
                duration: 2000,
                message: "Pools creadas correctamente",
                type: "is-success",
              });
              this.$emit("saved");
            } else {
              this.$buefy.toast.open({
                duration: 5000,
                message: `Error : ${response.data.message}`,
                type: "is-danger",
              });
            }
          })
          .finally(() => {
            this.saving = false;
          });
      },
      formatId(id) {
        return process.env.VITE_POOL_PREFIX + id.toString().padStart(3, "0");
      },
    },
  };
</script>

<style scoped></style>
