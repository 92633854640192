import Vue from "vue";
import Vuex from "vuex";
import camelcaseKeys from "camelcase-keys";
import { routes } from "@/api";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: JSON.parse(localStorage.getItem("user")) || {},
    isAuthenticated: !["", null].includes(localStorage.getItem("user")),
  },
  mutations: {
    AUTH_SUCCESS(state, { user }) {
      state.user = user;
      state.isAuthenticated = true;
    },
    LOGOUT(state) {
      state.user = {};
      state.isAuthenticated = false;
    },
  },
  actions: {
    login({ commit }, loginData) {
      return Vue.axios.post(routes.login, loginData).then((response) => {
        const user = {
          ...camelcaseKeys(response.data.user),
        };
        user.institution = user.institutionId;
        delete user.institutionId;

        localStorage.setItem("user", JSON.stringify(user));

        commit("AUTH_SUCCESS", { user });
      });
    },
    async logout({ commit }) {
      try {
        await Vue.axios.post(routes.logout);
      } finally {
        localStorage.clear();
        commit("LOGOUT");
      }
    },
  },
});
