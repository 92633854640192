<template>
  <div>
    <canvas id="line-chart"></canvas>
  </div>
</template>

<script>
  import { Chart, registerables } from "chart.js";
  import { lineChartDefaultOptions } from "@/components/plots/_defaults";

  Chart.register(...registerables);

  export default {
    name: "ChartjsLinePlot",
    props: {
      title: {
        type: String,
        default: "",
      },
      chartData: {
        type: Object,
        required: true,
      },
      yScaleLog: {
        type: Boolean,
        default: false,
      },
      options: {
        type: Object,
        default: null,
      },
      enableAnnotations: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        chart: null,
        chartOptions: lineChartDefaultOptions,
      };
    },
    watch: {
      chartData(value) {
        this.chart.data = value;
        this.chart.update();
      },
      options(value) {
        this.chart.options = value;
        this.chart.update();
      },
      yScaleLog(value) {
        this.chart.options.scales.y.type = value ? "logarithmic" : "linear";
        this.chart.update();
      },
    },
    created() {
      if (this.options !== null) {
        this.chartOptions = this.options;
      } else {
        this.chartOptions.plugins.title.text = this.title;
      }

      this.chartOptions.plugins.annotation = {
        annotations: {
          line1: {
            type: "line",
            yMin: 70000,
            yMax: 70000,
            borderColor: "rgb(255, 99, 132)",
            borderWidth: 2,
          },
        },
      };
    },
    mounted() {
      const ctx = document.getElementById("line-chart").getContext("2d");
      this.chart = new Chart(ctx, {
        type: "line",
        data: this.chartData,
        options: this.chartOptions,
      });
    },
  };
</script>

<style scoped>
  #line-chart {
    height: 420px;
  }
</style>
