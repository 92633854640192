<template>
  <div>
    <div>
      <strong>
        Seleccionadas: {{ selectedSamples.length }} /
        {{ samples.length }}
      </strong>
    </div>
    <b-table
      :data="formattedSamples"
      :columns="tableColumns"
      checkable
      :is-row-checkable="(row) => row.conclusion !== '---'"
      :checked-rows.sync="selectedSamples"
      :loading="loading"
    >
      <template slot="empty">
        <div class="table-empty" />
      </template>
    </b-table>
  </div>
</template>

<script>
  import { routes } from "@/api";
  import { formatDateTime } from "@/utils";

  export default {
    name: "SampleSelector",
    props: {
      batchIds: {
        type: Array,
        required: true,
      },
      active: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        samples: [],
        selectedSamples: [],
        statusOptions: [],
        loading: false,
        ownOnly: true,
        tableColumns: [
          {
            label: "N° Muestra",
            field: "sampleId",
            centered: true,
          },
          {
            label: "RUT / Pasaporte",
            field: "idString",
            centered: true,
          },
          {
            label: "Nombre",
            field: "fullName",
            centered: true,
          },
          {
            label: "Toma de Muestra",
            field: "samplingDatetime",
            centered: true,
          },
          {
            label: "Resultado",
            field: "conclusion",
            centered: true,
          },
        ],
      };
    },
    computed: {
      formattedSamples() {
        return this.samples.map((sample) => {
          return {
            sampleId: sample.sampleId,
            idString: sample.idString,
            fullName:
              `${sample.names} ${sample.firstLastname} ${sample.secondLastname}`
                .trim()
                .replace(/ +/i, " "),
            samplingDatetime: formatDateTime(sample.samplingDatetime, true),
            conclusion: sample.conclusion !== "" ? sample.conclusion : "---",
          };
        });
      },
    },
    watch: {
      active(value) {
        if (value) {
          this.fetchSamples();
          this.selectedSamples = [];
        }
      },
      selectedSamples(value) {
        this.$emit("update:samples", value);
      },
    },
    methods: {
      async fetchSamples() {
        if (this.batchIds.length === 0) {
          this.samples = [];
          return;
        }

        this.loading = true;

        try {
          const response = await this.axios.get(routes.listSamplesByBatch, {
            params: { batchId: this.batchIds.join(",") },
          });
          this.samples = response.data.results;
        } catch (e) {
          console.error(e);
        } finally {
          this.loading = false;
        }
      },
    },
  };
</script>

<style scoped></style>
