const lineChartDefaultOptions = {
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      min: 0,
      stacked: true,
      type: "linear",
    },
  },
  plugins: {
    title: {
      display: true,
      text: "",
      font: {
        size: 16,
      },
    },
    legend: { position: "bottom" },
    tooltip: {
      displayColors: true,
      mode: "index",
    },
  },
  pointRadius: 2,
  fill: true,
  responsive: true,
  maintainAspectRatio: false,
};

const stackedBarChartDefaultOptions = {
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      min: 0,
    },
  },
  interaction: {
    intersect: false,
    mode: "index",
  },
  plugins: {
    title: {
      display: true,
      text: "",
      font: {
        size: 16,
      },
    },
    legend: { position: "bottom" },
  },
  responsive: true,
  maintainAspectRatio: false,
};

const qPcrLineChartDefaultOptions = {
  interaction: {
    mode: "nearest",
    intersect: false,
  },
  animation: {
    duration: 0,
  },
  elements: {
    point: {
      radius: 0,
    },
  },
  scales: {
    y: {
      min: 0,
      title: {
        display: true,
        text: "Fluorecencia",
      },
    },
    x: {
      title: {
        display: true,
        text: "Ciclos",
      },
    },
  },
  plugins: {
    title: {
      display: true,
      text: "Curvas de qPCR",
      font: {
        size: 16,
      },
    },
    legend: {
      display: false,
      labels: {
        fontColor: "#000080",
      },
    },
  },
  maintainAspectRatio: false,
  responsive: true,
};

export {
  lineChartDefaultOptions,
  stackedBarChartDefaultOptions,
  qPcrLineChartDefaultOptions,
};
