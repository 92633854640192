<template>
  <div class="container mt-5">
    <h1 class="title">Agrupamiento (Pooling) de Muestras</h1>
    <div class="tabs">
      <ul>
        <my-tab-item
          route-name="ListUnvalidatedPools"
          text="Pools Sin Validar"
        />
        <my-tab-item route-name="ListRecentPools" text="Pools Recientes" />
        <my-tab-item route-name="CreateSamplePools" text="Crear Pools" />
      </ul>
    </div>
    <router-view />
  </div>
</template>

<script>
  import MyTabItem from "@/components/menus/MyTabItem.vue";
  export default {
    name: "SamplePooling",
    components: { MyTabItem },
    created() {
      if (this.$route.name === "SamplePooling") {
        this.$router.push({ name: "ListUnvalidatedPools" });
      }
    },
  };
</script>

<style scoped></style>
