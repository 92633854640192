import ChangePassword from "@/views/auth/ChangePassword.vue";
import EditUser from "@/views/auth/EditUser.vue";
import NewUser from "@/views/auth/NewUser.vue";
import ListUsers from "@/views/auth/ListUsers.vue";

const routes = [
  {
    path: "/change-password",
    name: "ChangePassword",
    component: ChangePassword,
    meta: {},
  },
  {
    path: "/users",
    name: "ListUsers",
    component: ListUsers,
    meta: { authorized: ["user-manager"] },
  },
  {
    path: "/users/new",
    name: "NewUser",
    component: NewUser,
    meta: { authorized: ["user-manager"] },
  },
  {
    path: "/users/:id/edit",
    name: "EditUser",
    component: EditUser,
    meta: { authorized: ["user-manager"] },
  },
];

export default routes;
