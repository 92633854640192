<template>
  <div>
    <canvas id="stacked-barchart"></canvas>
  </div>
</template>

<script>
  import { Chart, registerables } from "chart.js";
  import { stackedBarChartDefaultOptions } from "@/components/plots/_defaults";

  Chart.register(...registerables);

  export default {
    name: "ChartjsStackedBarplot",
    props: {
      title: {
        type: String,
        default: "",
      },
      chartData: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        chart: null,
        chartOptions: stackedBarChartDefaultOptions,
      };
    },
    watch: {
      chartData(val) {
        this.chart.data = val;
        if (val.isRelative !== undefined) {
          this.chart.options.scales.y.max = val.isRelative ? 100 : undefined;
        }
        this.chart.update();
      },
    },
    created() {
      this.chartOptions.plugins.title.text = this.title;
    },
    mounted() {
      const ctx = document.getElementById("stacked-barchart").getContext("2d");
      this.chart = new Chart(ctx, {
        type: "bar",
        data: this.chartData,
        options: this.chartOptions,
      });
    },
  };
</script>

<style scoped>
  #stacked-barchart {
    height: 420px;
  }
</style>
