<template>
  <div class="container">
    <section>
      <b-field grouped position="is-centered">
        <b-field label="Desde">
          <b-datepicker
            v-model="dateFrom"
            icon="calendar-today"
            :first-day-of-week="1"
            :day-names="datepickerOptions.dayNames"
            :month-names="datepickerOptions.monthNames"
          ></b-datepicker>
        </b-field>
        <b-field label="Hasta">
          <b-datepicker
            v-model="dateTo"
            icon="calendar-today"
            :first-day-of-week="1"
            :day-names="datepickerOptions.dayNames"
            :month-names="datepickerOptions.monthNames"
            trap-focus
          ></b-datepicker>
        </b-field>
        <b-field>
          <template slot="label"><br /></template>
          <b-button type="is-primary" @click="loadData"> Filtrar </b-button>
        </b-field>
      </b-field>
    </section>
    <section class="mt-4">
      <b-table
        :data="formattedDtata"
        :columns="tableColumns"
        :loading="loading"
        narrowed
      >
        <template slot="empty"><div class="table-empty" /></template>
      </b-table>
    </section>
    <section v-if="data.length > 0" class="mt-5 has-text-centered">
      <b-field>
        <b-button class="is-primary" icon-left="content-save">
          <download-csv
            :data="formattedDtata"
            :separator-excel="excelCompatible"
            :fields="Object.values(tableLabels)"
            :name="`daily_summary_${$date().format('YYYYMMDD')}.csv`"
          >
            Descargar (.csv)
          </download-csv>
        </b-button>
      </b-field>

      <b-field>
        <b-checkbox v-model="excelCompatible">
          Hacer compatible con MS Excel
        </b-checkbox>
      </b-field>
    </section>
  </div>
</template>

<script>
  import { routes } from "@/api";
  import { datepickerOptions } from "@/utils";

  export default {
    name: "DailySummaryReport",
    data() {
      return {
        dateFrom: this.$date().subtract(1, "months").toDate(),
        dateTo: new Date(),
        data: [],
        excelCompatible: false,
        tableLabels: {
          date: "Fecha",
          received: "Ingresadas",
          analyzed: "Total Procesadas",
          on_schedule: "Procesadas < 24 Hrs",
          positive: "Positivas",
          negative: "Negativas",
          inconclusive: "No Concluyentes",
          rejected: "Rechazadas",
        },
        datepickerOptions,
        loading: false,
      };
    },
    computed: {
      tableColumns() {
        return Object.values(this.tableLabels).map((x) => {
          return { field: x, label: x, centered: true };
        });
      },
      formattedDtata() {
        return this.data.map((x) => {
          return Object.entries(x).reduce((o, [k, v]) => {
            o[this.tableLabels[k]] =
              k === "date" ? this.$date(v).format("DD-MM-YYYY") : v;
            return o;
          }, {});
        });
      },
    },
    created() {
      this.loadData();
    },
    methods: {
      loadData() {
        this.loading = true;
        this.axios
          .get(routes.dailySummary, {
            params: {
              date_from: this.$date(this.dateFrom).format("YYYY-MM-DD"),
              date_to: this.$date(this.dateTo).format("YYYY-MM-DD"),
            },
          })
          .then((response) => {
            this.data = response.data.results;
          })
          .catch((e) => {
            this.$log.error(e);
          })
          .finally(() => {
            this.loading = false;
          });
      },
    },
  };
</script>

<style scoped></style>
