<template>
  <div id="app">
    <my-header />
    <router-view :key="$route.fullPath" />
    <footer class="mt-6"></footer>
  </div>
</template>

<script>
  import MyHeader from "@/components/menus/MyHeader.vue";
  import { routes } from "@/api";

  export default {
    name: "App",
    components: { MyHeader },
    computed: {
      tokenExpiration() {
        return this.$date(this.$store.state.user.tokenExpiration);
      },
    },
    created() {
      // check every minute if sesion has expired
      setInterval(
        function () {
          if (
            this.$store.state.isAuthenticated &&
            this.tokenExpiration.isValid()
          ) {
            const now = this.$date();
            if (this.tokenExpiration < now) {
              this.checkCredentials();
            }
          }
        }.bind(this),
        60000
      );
    },
    methods: {
      checkCredentials() {
        this.axios.get(routes.dummyCheck).catch(() => {
          this.$buefy.snackbar.open({
            message: "Sesión expirada, por favor inicie sesión nuevamente",
            type: "is-primary",
            actionText: "OK",
            indefinite: true,
          });
        });
      },
    },
  };
</script>

<style scoped></style>
