<template>
  <section>
    <h1 class="subtitle">Reporte Masivo</h1>
    <p class="has-text-grey">
      Esta opción permite generar un compilado de los reportes de un conjunto de
      muestras.
    </p>

    <b-steps
      v-model="currentStep"
      :rounded="false"
      :has-navigation="false"
      class="mt-4"
    >
      <b-step-item step="1" label="Seleccionar Lotes">
        <p class="has-text-grey">
          Seleccione los lotes a utilizar para el reporte.
        </p>
        <b-field grouped position="is-right" class="mb-5">
          <b-button
            icon-right="arrow-right"
            type="is-primary"
            :disabled="batches.length === 0"
            @click="goToSampleSelection"
          >
            Siguiente
          </b-button>
        </b-field>
        <b-message v-if="incompleteBatches" type="is-warning">
          <strong>Advertencia</strong> <br />
          Alguno(s) de los lotes seleccionados no tiene todas sus muestras
          procesadas. Las muestras faltantes no podrán ser incluidas en el
          reporte.
        </b-message>
        <batch-selector @update:batches="batches = $event" />
      </b-step-item>
      <b-step-item step="2" label="Seleccionar Muestras">
        <p class="has-text-grey">
          Seleccione las muestras a incluir en el reporte.
        </p>
        <b-field grouped position="is-right">
          <b-button
            icon-right="arrow-right"
            type="is-primary"
            :disabled="samples.length === 0"
            @click="currentStep = 2"
          >
            Siguiente
          </b-button>
        </b-field>
        <sample-selector
          :batch-ids="batches.map((b) => b.id)"
          :active="currentStep === 1"
          @update:samples="samples = $event"
        />
      </b-step-item>
      <b-step-item step="3" label="Generar Reporte">
        <p class="has-text-grey">
          Rellene los campos para generar los informes.
        </p>
        <div class="columns">
          <div class="column is-two-thirds-desktop mx-auto">
            <massive-report-form :samples="samples" class="mt-5" />
          </div>
        </div>
      </b-step-item>
    </b-steps>
  </section>
</template>

<script>
  import BatchSelector from "@/components/batches/BatchSelector.vue";
  import SampleSelector from "@/components/samples/SampleSelector.vue";
  import MassiveReportForm from "@/components/reports/MassiveReportForm.vue";
  export default {
    name: "MassiveReport",
    components: { MassiveReportForm, SampleSelector, BatchSelector },
    data() {
      return {
        currentStep: 0,
        loading: false,
        batches: [],
        samples: [],
      };
    },
    computed: {
      incompleteBatches() {
        return this.batches.some((batch) => batch.status !== "processed");
      },
    },
    methods: {
      goToSampleSelection() {
        this.currentStep = 1;
        this.samples = [];
      },
    },
  };
</script>

<style scoped></style>
