<template>
  <div>
    <section>
      <b-field horizontal label="Institución">
        <b-input v-model="institutionName"></b-input>
      </b-field>
      <b-field horizontal label="RUT">
        <b-input v-model="institutionId"></b-input>
      </b-field>
      <b-field horizontal label="Origen">
        <b-input v-model="origin"></b-input>
      </b-field>
      <b-field horizontal label="Muestras">
        <span>{{ samples.length }} [{{ formattedResultSummary }}]</span>
      </b-field>
      <b-field horizontal>
        <b-message>
          <strong>Nota</strong> <br />
          La generación de informes podría tomar algunos minutos dependiendo de
          la cantidad de muestras a incluir.
        </b-message>
      </b-field>
      <b-field horizontal>
        <b-button
          type="is-primary"
          :disabled="hasEmptyFields"
          :loading="loading"
          @click="generateReports"
        >
          Generar Informes
        </b-button>
      </b-field>
    </section>
  </div>
</template>

<script>
  import { routes } from "@/api.js";
  import { saveAs } from "file-saver";

  export default {
    name: "MassiveReportForm",
    props: {
      samples: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        institutionName: "",
        institutionId: "",
        origin: "",
        loading: false,
      };
    },
    computed: {
      hasEmptyFields() {
        return (
          this.institutionName === "" ||
          this.institutionId === "" ||
          this.origin === ""
        );
      },
      sampleResultSummary() {
        return this.samples.reduce((o, sample) => {
          o[sample.conclusion] = (o[sample.conclusion] || 0) + 1;
          return o;
        }, {});
      },
      formattedResultSummary() {
        return Object.entries(this.sampleResultSummary)
          .map(([key, value]) => `${key}: ${value}`)
          .join(", ");
      },
    },
    methods: {
      async generateReports() {
        this.loading = true;
        try {
          const response = await this.axios.post(
            routes.massiveReport,
            {
              institutionName: this.institutionName,
              institutionId: this.institutionId,
              origin: this.origin,
              samples: this.samples.map((sample) => sample.sampleId),
            },
            { responseType: "arraybuffer" }
          );

          const blob = new Blob([response.data], {
            type: "application/octet-stream",
          });
          const dt = this.$date().format("YYYYMMDDHHmm");
          saveAs(blob, `reports_massive_${this.samples.length}_${dt}.zip`);
        } catch (e) {
          console.error(e);
        } finally {
          this.loading = false;
        }
      },
    },
  };
</script>

<style scoped></style>
