<template>
  <div>
    <h2 class="subtitle mb-2">Recientes</h2>
    <p class="has-text-grey mb-4">
      Pools validadas recientemente (2 últimos días).
    </p>

    <pool-list :endpoint="route" :show-results="true" />
  </div>
</template>

<script>
  import PoolList from "@/components/pools/PoolList.vue";
  import { routes } from "@/api";
  export default {
    name: "ListRecentPools",
    components: { PoolList },
    data() {
      return {
        route: routes.recentPools,
      };
    },
  };
</script>

<style scoped></style>
