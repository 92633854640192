import SeqCellInput from "@/views/sequencing/SeqCellInput.vue";
import SeqCellsList from "@/views/sequencing/SeqCellsList.vue";
import SeqCellDetails from "@/views/sequencing/SeqCellDetails.vue";
import ImportExtSamples from "@/views/sequencing/ImportExtSamples.vue";

const routes = [
  {
    path: "/sequencing-cells/new",
    name: "SeqCellInput",
    component: SeqCellInput,
    meta: { authorized: ["seq-analyst"] },
  },
  {
    path: "/sequencing-cells/",
    name: "SeqCellsList",
    component: SeqCellsList,
    meta: { authorized: ["seq-analyst"] },
  },
  {
    path: "/sequencing-cells/:id/details",
    name: "SeqCellDetails",
    component: SeqCellDetails,
    meta: { authorized: ["seq-analyst"] },
  },
  {
    path: "/sequencing/import-external-samples",
    name: "ImportExtSamples",
    component: ImportExtSamples,
    meta: { authorized: ["seq-analyst"] },
  },
];

export default routes;
