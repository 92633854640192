<template>
  <div>
    <div class="has-text-right">
      <b-checkbox v-model="ownOnly">
        Mostrar sólo lotes propios (CADI-UMAG)
      </b-checkbox>
    </div>
    <b-table
      :data="batches"
      checkable
      :checked-rows.sync="selectedBatches"
      :loading="loading"
      paginated
    >
      <b-table-column
        v-slot="props"
        field="id"
        label="N°"
        cell-class="valign-mid"
        centered
      >
        {{ props.row.id }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="institution"
        label="Institución"
        cell-class="valign-mid"
      >
        {{ props.row.institution }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="samples"
        label="Muestras"
        cell-class="valign-mid"
        centered
        numeric
      >
        <b-tooltip
          v-if="props.row.counts"
          :label="formatCounts(props.row.counts)"
          type="is-light"
          dashed
        >
          <span>{{ props.row.samples }}</span>
        </b-tooltip>
        <span v-else>{{ props.row.samples }}</span>
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="status"
        label="Estado"
        cell-class="valign-mid"
        centered
      >
        {{ statusOptions[props.row.status] }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="datetime"
        label="Último cambio"
        cell-class="valign-mid"
        centered
      >
        {{ formatDatetime(props.row.lastUpdate) }}
      </b-table-column>
      <template slot="empty"><div class="table-empty" /></template>
    </b-table>
  </div>
</template>

<script>
  import { routes } from "@/api";
  import { formatDateTime } from "@/utils";

  export default {
    name: "BatcheSelector",
    data() {
      return {
        batches: [],
        selectedBatches: [],
        statusOptions: [],
        loading: false,
        ownOnly: true,
      };
    },
    watch: {
      selectedBatches(value) {
        this.$emit("update:batches", value);
      },
      ownOnly() {
        this.fetchBatches();
      },
    },
    mounted() {
      this.fetchBatches();
    },
    methods: {
      async fetchBatches() {
        this.loading = true;

        try {
          const params = this.ownOnly
            ? { institutionId: process.env.VITE_OWN_INSTITUTION_ID }
            : {};
          const response = await this.axios.get(
            routes.listBatches.replace(":type", "all"),
            {
              params: params,
            }
          );
          this.batches = response.data.results;
          this.statusOptions = response.data.statusOptions;
        } catch (e) {
          console.error(e);
        } finally {
          this.loading = false;
        }
      },
      formatCounts(counts) {
        return Object.entries(counts)
          .map(([k, v]) => `${k}: ${v}`)
          .join(", ");
      },
      formatDatetime(datetime) {
        return formatDateTime(datetime, true);
      },
    },
  };
</script>

<style scoped></style>
