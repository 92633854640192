import SamplePooling from "@/views/pools/SamplePooling.vue";
import ListRecentPools from "@/views/pools/ListRecentPools.vue";
import ListUnvalidatedPools from "@/views/pools/ListUnvalidatedPools.vue";
import CreateSamplePools from "@/views/pools/CreateSamplePools.vue";

const routes = [
  {
    path: "/samples/pools",
    name: "SamplePooling",
    component: SamplePooling,
    meta: { authorized: ["extr-analyst"] },
    children: [
      {
        path: "list/recent",
        name: "ListRecentPools",
        component: ListRecentPools,
        meta: { authorized: ["extr-analyst"] },
      },
      {
        path: "list/unvalidated",
        name: "ListUnvalidatedPools",
        component: ListUnvalidatedPools,
        meta: { authorized: ["extr-analyst"] },
      },
      {
        path: "create",
        name: "CreateSamplePools",
        component: CreateSamplePools,
        meta: { authorized: ["extr-analyst"] },
      },
    ],
  },
];

export default routes;
