import ExtractionPlateInput from "@/views/plates/ExtractionPlateInput.vue";
import ExtractionPlatesList from "@/views/plates/ExtractionPlatesList.vue";
import ExtractionPlateDetails from "@/views/plates/ExtractionPlateDetails.vue";
import PcrPlateInput from "@/views/plates/PcrPlateInput.vue";
import PcrPlatesList from "@/views/plates/PcrPlatesList.vue";
import PcrPlateDetails from "@/views/plates/PcrPlateDetails.vue";

const routes = [
  {
    path: "/plates/extraction/new",
    name: "ExtractionPlateInput",
    component: ExtractionPlateInput,
    meta: { authorized: ["extr-analyst"] },
  },
  {
    path: "/plates/extraction",
    name: "ExtractionPlatesList",
    component: ExtractionPlatesList,
    meta: { authorized: ["extr-analyst"] },
  },
  {
    path: "/plates/extraction/:id/details",
    name: "ExtractionPlateDetails",
    component: ExtractionPlateDetails,
    meta: { authorized: ["extr-analyst"] },
  },
  {
    path: "/plates/pcr/new",
    name: "PcrPlateInput",
    component: PcrPlateInput,
    meta: { authorized: ["pcr-analyst"] },
  },
  {
    path: "/plates/pcr",
    name: "PcrPlatesList",
    component: PcrPlatesList,
    meta: { authorized: ["pcr-analyst"] },
  },
  {
    path: "/plates/pcr/:id/details",
    name: "PcrPlateDetails",
    component: PcrPlateDetails,
    meta: { authorized: ["pcr-analyst"] },
  },
];

export default routes;
