<script>
  import { routes } from "@/api";
  import NonCovidSamplesList from "@/components/samples/NonCovidSamplesList.vue";

  export default {
    name: "SearchNonCovidByText",
    components: { NonCovidSamplesList },
    data() {
      return {
        searchText: "",
        searching: false,
        samplesData: undefined,
      };
    },
    methods: {
      searchSamples() {
        this.searching = true;
        this.axios
          .get(routes.searchNonCovidSamples, {
            params: { query: this.searchText },
          })
          .then((response) => {
            this.samplesData = response.data.results;
          })
          .catch((e) => {
            this.$log.error(e);
          })
          .finally(() => {
            this.searching = false;
          });
      },
    },
  };
</script>

<template>
  <section>
    <div class="columns is-centered">
      <div class="column is-9">
        <p class="has-text-grey">
          Esta página permite buscar muestras (no COVID-19). Se puede utilizar
          el número de muestra, RUN, o apellidos del paciente.
        </p>
      </div>
    </div>
    <div class="columns">
      <div class="column is-half is-offset-one-quarter">
        <form @submit.prevent>
          <b-field>
            <b-input
              v-model="searchText"
              placeholder="N° de Muestra, RUN, Apellido..."
              expanded
            >
            </b-input>
            <p class="control">
              <b-button
                class="button is-primary"
                :loading="searching"
                native-type="submit"
                @click="searchSamples"
              >
                Buscar
              </b-button>
            </p>
          </b-field>
        </form>
      </div>
    </div>
    <div v-if="samplesData && samplesData.length > 0">
      <non-covid-samples-list :searching="searching" :samples="samplesData" />
    </div>
    <div
      v-else-if="samplesData && samplesData.length === 0"
      class="has-text-centered"
    >
      <span class="has-text-danger">
        No se encontró ningún resultado con el término buscado.
      </span>
    </div>
  </section>
</template>

<style scoped></style>
