<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title is-centered">
        {{ title }}
      </div>
    </div>
    <div class="card-content px-3 py-2">
      <div
        v-for="(sample, i) in selectedSamples"
        :key="sample"
        class="content has-text-centered my-2"
      >
        <span>{{ sample }}</span>
        <b-button
          class="is-pulled-right"
          type="is-text"
          size="is-small"
          @click="removeFromPool(i)"
        >
          <b-icon icon="close" type="is-danger" />
        </b-button>
      </div>
      <div
        v-if="availableSamples.length > 0 && selectedSamples.length < maxSize"
        class="content"
      >
        <b-field>
          <b-autocomplete
            v-model="searchInput"
            :data="filteredSamples"
            :open-on-focus="true"
            @keyup.enter.native="addToPool"
          />
          <p class="control">
            <b-button
              icon-left="plus"
              class="button is-primary"
              :disabled="!availableSamples.includes(searchInput)"
              @click="addToPool"
            />
          </p>
        </b-field>
      </div>
    </div>
    <div class="card-footer">
      <b-button type="is-text" expanded @click="$emit('delete-pool')">
        <b-icon icon="delete" type="is-danger" />
      </b-button>
    </div>
  </div>
</template>

<script>
  export default {
    name: "SamplePoolEditor",
    props: {
      availableSamples: {
        type: Array,
        required: true,
      },
      selectedSamples: {
        type: Array,
        default: () => [],
      },
      title: {
        type: String,
        default: "Pool",
      },
      maxSize: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        searchInput: "",
      };
    },
    computed: {
      filteredSamples() {
        return this.availableSamples.filter((x) => {
          const xStr = x.toString();
          return (
            xStr.startsWith(this.searchInput) || xStr.endsWith(this.searchInput)
          );
        });
      },
    },
    methods: {
      addToPool() {
        const sample = parseInt(this.searchInput);
        if (this.availableSamples.includes(sample)) {
          this.$emit("add-to-pool", sample);
          this.searchInput = "";
        }
      },
      removeFromPool(idx) {
        this.$emit("delete-from-pool", idx);
      },
    },
  };
</script>

<style scoped></style>
