<template>
  <div class="modal-card has-radius">
    <div class="modal-card-body">
      <ul class="content">
        <li>Nombre: {{ kitInfo.fullname }}</li>
        <li>Pocillos: {{ kitInfo.wells }}</li>
        <li>
          Analitos:
          <ul>
            <li
              v-for="targetType in Object.keys(kitInfo.targets)"
              :key="targetType"
            >
              <span class="capitalized">{{ targetType }}</span>
              <ul>
                <li v-for="target in kitInfo.targets[targetType]" :key="target">
                  <strong>{{ target.name }}</strong
                  >, fluoróforo {{ target.reporter }}, punto de corte
                  {{ formatCutoffs(target.cutoffs) }}
                  {{ target.prefix ? `(prefijo ${target.prefix})` : "" }}
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    name: "KitInfoModal",
    props: {
      kitInfo: {
        type: Object,
        required: true,
      },
    },
    methods: {
      formatCutoffs(cutoffs) {
        if (cutoffs.lower) {
          return `${cutoffs.lower} < X < ${cutoffs.upper}`;
        } else {
          return `X < ${cutoffs.upper}`;
        }
      },
    },
  };
</script>

<style scoped>
  .capitalized {
    text-transform: capitalize;
  }
  .has-radius {
    border-radius: 5px;
  }
</style>
