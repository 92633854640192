<template>
  <div class="control" :class="{ 'is-expanded': expanded }">
    <label
      ref="label"
      class="b-checkbox checkbox button big-button"
      :class="[
        checked ? type : null,
        size,
        {
          'is-disabled': disabled,
          'is-focused': isFocused,
        },
      ]"
      :disabled="disabled"
      @click="focus"
      @keydown.prevent.enter="$refs.label.click()"
    >
      <slot />
      <input
        ref="input"
        v-model="computedValue"
        type="checkbox"
        :disabled="disabled"
        :required="required"
        :name="name"
        :value="nativeValue"
        @click.stop
        @focus="isFocused = true"
        @blur="isFocused = false"
      />
    </label>
  </div>
</template>

<script>
  import CheckRadioMixin from "buefy/src/utils/CheckRadioMixin";

  export default {
    name: "BigBCheckboxButton",
    mixins: [CheckRadioMixin],
    props: {
      type: {
        type: String,
        default: "is-primary",
      },
      expanded: Boolean,
    },
    data() {
      return {
        isFocused: false,
      };
    },
    computed: {
      checked() {
        if (Array.isArray(this.newValue)) {
          return this.newValue.indexOf(this.nativeValue) >= 0;
        }
        return this.newValue === this.nativeValue;
      },
    },
  };
</script>
<style scoped>
  .big-button {
    height: 70px;
    min-width: 40px;
    padding: 4px;
    z-index: 0;
    transition: transform 0.15s;
  }
</style>
