import QpcrABI from "@/views/qpcr-analysis/QpcrABI.vue";
import QpcrTrainingList from "@/views/qpcr-analysis/QpcrTrainingList.vue";
import QpcrTrainingExperiment from "@/views/qpcr-analysis/QpcrTrainingExperiment.vue";

const routes = [
  {
    path: "/qpcr-analysis-abi",
    name: "QpcrABI",
    component: QpcrABI,
    meta: { authorized: ["extra-features"] },
  },
  {
    path: "/qpcr-training",
    name: "QpcrTrainingList",
    component: QpcrTrainingList,
    meta: { authorized: ["extra-features"] },
  },
  {
    path: "/qpcr-training/experiment/:id",
    name: "QpcrTrainingExperiment",
    component: QpcrTrainingExperiment,
    meta: { authorized: ["extra-features"] },
  },
];

export default routes;
