<template>
  <li v-if="hasPermission($props.routeName)" :class="tabClasses">
    <router-link :to="{ name: $props.routeName }">
      {{ $props.text }}
    </router-link>
  </li>
</template>

<script>
  import { hasPermission } from "@/utils";

  export default {
    name: "MyTabItem",
    props: {
      routeName: { type: String, required: true },
      text: { type: String, required: true },
    },
    computed: {
      tabClasses() {
        return {
          "is-active": this.$route.name === this.$props.routeName,
        };
      },
    },
    methods: {
      hasPermission(routeName) {
        return hasPermission(routeName);
      },
    },
  };
</script>

<style scoped></style>
