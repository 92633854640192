<template>
  <b-navbar-item
    tag="router-link"
    :to="{
      name: routeName,
      params: routeParams,
    }"
    :active="isActive"
  >
    <b-icon v-if="icon !== ''" :icon="icon" />
    {{ label }}
  </b-navbar-item>
</template>

<script>
  export default {
    name: "MyNavbarItem",
    props: {
      routeName: { type: String, required: true },
      routeParams: { type: Object, default: () => ({}) },
      label: { type: String, required: true },
      icon: { type: String, required: false, default: "" },
    },
    computed: {
      isActive() {
        const sameRoute = this.$route.name === this.$props.routeName;
        const sameParams =
          JSON.stringify(this.$route.params) ===
          JSON.stringify(this.$props.routeParams);

        return sameRoute && sameParams;
      },
    },
  };
</script>

<style scoped></style>
