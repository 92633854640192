<template>
  <div>
    <h2 class="subtitle">{{ title }}</h2>

    <form :id="formId" @submit.prevent>
      <slot :values="formValues"></slot>

      <p class="control">
        <b-button
          :loading="submitLoading"
          class="button is-primary"
          @click="submit"
        >
          Enviar
        </b-button>
      </p>
    </form>

    <div
      v-for="[type, details] of Object.entries(message_types)"
      :key="type"
      class="mt-4"
    >
      <template v-if="type in messages">
        <b-message
          :title="details.messageTitle"
          :type="details.messageType"
          aria-close-label="Cerrar mensaje"
        >
          <ul>
            <li v-for="(message, i) in messages[type]" :key="i">
              {{ message }}
            </li>
          </ul>
        </b-message>
      </template>
    </div>
  </div>
</template>

<script>
  export default {
    name: "InputForm",
    props: {
      submitUrl: {
        type: String,
        required: true,
      },
      formId: {
        type: String,
        required: true,
      },
      title: {
        type: String,
        default: "Datos",
      },
      defaultValues: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        formValues: { ...this.$props.defaultValues },
        messages: {},
        submitLoading: false,
        message_types: {
          warnings: { messageTitle: "Advertencias", messageType: "is-warning" },
          errors: { messageTitle: "Errores", messageType: "is-danger" },
        },
      };
    },
    methods: {
      submit() {
        this.messages = {};
        this.submitLoading = true;

        // get form data
        let form = document.querySelector(`#${this.$props.formId}`);
        let formData = new FormData(form);

        // transform dates to ISO format
        for (let [field, value] of Object.entries(this.formValues)) {
          if (value instanceof Date) {
            formData.set(field, this.$date(value).format("YYYY-MM-DD HH:mm"));
          }
        }

        this.axios
          .post(this.$props.submitUrl, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response) => {
            let status = response.data.status;
            if (["warning", "error"].includes(status)) {
              this.$buefy.toast.open({
                duration: 2000,
                message: "Datos ingresados",
              });
              this.messages[`${status}s`] = response.data.messages;
            } else {
              this.$buefy.toast.open({
                duration: 2000,
                message: "Datos ingresados correctamente",
                type: "is-success",
              });
            }
          })
          .catch(() => {
            this.messages = { errors: ["Error del servidor."] };
          })
          .finally(() => {
            this.submitLoading = false;
            this.formValues = { ...this.$props.defaultValues };
          });
      },
    },
  };
</script>

<style scoped></style>
