<template>
  <div class="container mt-5">
    <user-form :is-new="true" :saving="saving" @submit="saveUser" />
  </div>
</template>

<script>
  import UserForm from "@/components/auth/UserForm.vue";
  import { routes } from "@/api";
  import snakecaseKeys from "snakecase-keys";

  export default {
    name: "NewUser",
    components: {
      UserForm,
    },
    data() {
      return {
        saving: false,
      };
    },
    methods: {
      async saveUser(userData) {
        this.saving = true;
        const response = await this.axios.post(
          routes.createUser,
          snakecaseKeys(userData)
        );

        if (response.data.status === "OK") {
          this.$buefy.dialog.alert({
            title: "Usuario Creado",
            message: `usuario: ${userData.username}<br>contraseña: ${userData.password}`,
            html: true,
            type: "is-success",
            onConfirm: () => {
              this.$router.push({ name: "ListUsers" });
            },
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Error",
            message: response.data.message,
            type: "is-danger",
            hasIcon: true,
          });
        }
        this.saving = false;
      },
    },
  };
</script>

<style scoped></style>
