import OnDemandReports from "@/views/reports/OnDemandReports.vue";
import MinsalReports from "@/components/reports/MinsalReports.vue";
import DailySummaryReport from "@/views/reports/DailySummaryReport.vue";
import IndividualReport from "@/views/reports/IndividualReport.vue";
import MassiveReport from "@/views/reports/MassiveReport.vue";
import PositiveReports from "@/views/reports/PositiveReports.vue";

const routes = [
  {
    path: "/reports/on-demand",
    name: "OnDemandReports",
    component: OnDemandReports,
    meta: {},
    children: [
      {
        path: "minsal",
        name: "MinsalReports",
        component: MinsalReports,
        meta: { authorized: ["specialist", "extr-analyst", "pcr-analyst"] },
      },
      {
        path: "daily",
        name: "DailySummaryReport",
        component: DailySummaryReport,
        meta: {},
      },
      {
        path: "individual",
        name: "IndividualReport",
        component: IndividualReport,
        meta: {
          authorized: [
            "sample-manager",
            "specialist",
            "extr-analyst",
            "pcr-analyst",
            "report-viewer",
          ],
        },
      },
      {
        path: "massive",
        name: "MassiveReport",
        component: MassiveReport,
        meta: {
          authorized: [
            "sample-manager",
            "specialist",
            "extr-analyst",
            "pcr-analyst",
            "report-viewer",
          ],
        },
      },
      {
        path: "positive",
        name: "PositiveReports",
        component: PositiveReports,
        meta: {
          authorized: [
            "specialist",
            "extr-analyst",
            "pcr-analyst",
            "report-viewer",
          ],
        },
      },
    ],
  },
];

export default routes;
