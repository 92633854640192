import Vue from "vue";
import dayjs from "dayjs";

import "dayjs/locale/es";

Object.defineProperties(Vue.prototype, {
  $date: {
    get() {
      return dayjs;
    },
  },
});

dayjs.locale("es");
