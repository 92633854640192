<template>
  <div class="container mt-5">
    <div class="level">
      <div class="level-left">
        <h2 class="subtitle">
          <strong>Muestras seleccionadas: {{ samples.length }}</strong>
        </h2>
      </div>
      <div class="level-right">
        <div class="level-item">
          <b-field horizontal label="Tamaño">
            <b-numberinput
              v-model="poolSize"
              min="2"
              max="5"
              class="number-input-150"
              controls-position="compact"
            />
          </b-field>
        </div>
        <div class="level-item">
          <b-field>
            <b-button type="is-primary" @click="atutoPool">
              Generar automáticamente
            </b-button>
          </b-field>
        </div>
      </div>
    </div>

    <div class="columns is-multiline">
      <div
        v-for="(pool, i) in pools"
        :key="i"
        class="column is-2-fullhd is-one-fifth"
      >
        <sample-pool-editor
          :title="`Pool ${i + 1}`"
          :available-samples="availableSamples"
          :selected-samples="pool"
          :max-size="poolSize"
          @delete-pool="$emit('delete-pool', i)"
          @add-to-pool="$emit('add-to-pool', i, $event)"
          @delete-from-pool="$emit('delete-from-pool', i, $event)"
        />
      </div>
      <div
        v-if="availableSamples.length > 0"
        class="column is-2-fullhd is-one-fifth"
      >
        <b-button
          icon-left="plus"
          type="is-primary"
          expanded
          @click="$emit('add-pool')"
        >
          Agregar Pool
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
  import SamplePoolEditor from "@/components/pools/SamplePoolEditor.vue";
  export default {
    name: "SamplePoolsCreator",
    components: { SamplePoolEditor },
    props: {
      samples: {
        type: Array,
        required: true,
      },
      pools: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        poolSize: 5,
      };
    },
    computed: {
      availableSamples() {
        const selectedSamples = new Set(this.pools.flat());
        return this.samples.filter((x) => !selectedSamples.has(x));
      },
    },
    watch: {
      pools(value) {
        this.$emit("update:pools", value);
      },
    },
    methods: {
      atutoPool() {
        const newPools = Array.from(
          { length: Math.ceil(this.samples.length / this.poolSize) },
          (v, i) =>
            this.samples.slice(
              i * this.poolSize,
              i * this.poolSize + this.poolSize
            )
        );
        this.$emit("update:pools", newPools);
      },
    },
  };
</script>

<style scoped>
  .number-input-150 {
    width: 150px;
  }
</style>
