import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

import authRoutes from "@/router/auth";
import batchesRoutes from "@/router/batches";
import platesRoutes from "@/router/plates";
import poolRoutes from "@/router/pools";
import qPcrRoutes from "@/router/qpcr-analysis";
import reportRoutes from "@/router/reports";
import samplesRoutes from "@/router/samples";
import sequencingRoutes from "@/router/sequencing";

import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import { hasPermission } from "@/utils";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {},
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {},
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/", // import.meta.env.BASE_URL,
  routes: [
    ...routes,
    ...authRoutes,
    ...batchesRoutes,
    ...platesRoutes,
    ...poolRoutes,
    ...reportRoutes,
    ...qPcrRoutes,
    ...samplesRoutes,
    ...sequencingRoutes,
  ],
});

router.beforeEach((to, from, next) => {
  // if user not valid, force logout
  if (!(store.state.user instanceof Object)) {
    store.dispatch("logout").then(() => {
      next({ name: "Login" });
    });
  }

  if (!store.state.isAuthenticated && to.name !== "Login") {
    // if user not authenticated, redirect to login automaticaly
    next({ name: "Login" });
  } else if (store.state.isAuthenticated && to.name !== "Login") {
    // check if user has permission
    if (hasPermission(to.name, to.params)) {
      next();
    } else {
      next({ name: "Home" });
    }
  } else {
    next();
  }
  next();
});

export default router;
