<template>
  <div class="container mt-5">
    <h1 class="title">Informes Libres</h1>
    <div class="tabs">
      <ul>
        <my-tab-item route-name="DailySummaryReport" text="Resumen Diario" />
        <my-tab-item route-name="MinsalReports" text="Informes Minsal" />
        <my-tab-item route-name="IndividualReport" text="Individuales" />
        <my-tab-item route-name="MassiveReport" text="Masivo" />
        <my-tab-item route-name="PositiveReports" text="Positivos" />
      </ul>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
  import MyTabItem from "@/components/menus/MyTabItem.vue";
  export default {
    name: "OnDemandReports",
    components: {
      MyTabItem,
    },
    created() {
      if (this.$route.name === "OnDemandReports") {
        this.$router.push({ name: "DailySummaryReport" });
      }
    },
  };
</script>

<style scoped></style>
