<template>
  <div>
    <h1 class="subtitle">{{ title }}</h1>
    <b-field label="Desde">
      <b-datetimepicker
        v-model="datetimeFrom"
        placeholder="Seleccione fecha / hora..."
        icon="calendar-today"
        :datepicker="datepickerOptions"
        :timepicker="{ hourFormat: '24' }"
      />
    </b-field>
    <b-field label="Hasta">
      <b-datetimepicker
        v-model="datetimeTo"
        placeholder="Seleccione fecha / hora..."
        icon="calendar-today"
        :datepicker="datepickerOptions"
        :timepicker="{ hourFormat: '24' }"
      />
    </b-field>
    <b-field v-if="passwordProtectOption">
      <b-checkbox v-model="protect">Proteger con contraseña</b-checkbox>
    </b-field>
    <b-button
      type="is-primary"
      :disabled="datetimeFrom >= datetimeTo"
      :loading="generating"
      @click="submit"
    >
      Generar Informe
    </b-button>
  </div>
</template>

<script>
  import { routes } from "@/api";
  import { saveAs } from "file-saver";
  import { datepickerOptions } from "@/utils";

  export default {
    name: "MinsalReport",
    props: {
      title: {
        type: String,
        required: true,
      },
      apiRoute: {
        type: String,
        required: true,
      },
      namePrefix: {
        type: String,
        default: "CADIUMAG_",
      },
      passwordProtectOption: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        datetimeFrom: this.$date()
          .add(-1, "day")
          .hour(process.env.VITE_DAILY_CUTOFF_TIME)
          .minute(0)
          .toDate(),
        datetimeTo: this.$date()
          .hour(process.env.VITE_DAILY_CUTOFF_TIME)
          .minute(0)
          .add(-1, "minute")
          .toDate(),
        protect: true,
        generating: false,
        datepickerOptions,
      };
    },
    methods: {
      submit() {
        this.generating = true;
        let data = {
          datetimeFrom: this.$date(this.datetimeFrom).format(
            "YYYY-MM-DD HH:mm"
          ),
          datetimeTo: this.$date(this.datetimeTo).format("YYYY-MM-DD HH:mm"),
        };
        if (this.$props.passwordProtectOption) {
          data.protect = this.protect;
        }

        this.axios
          .post(routes[this.$props.apiRoute], data, { responseType: "blob" })
          .then((response) => {
            const blob = new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            saveAs(
              blob,
              `${this.$props.namePrefix}${this.$date(this.date).format(
                "DDMMYYYY_HHmm"
              )}.xlsx`
            );
          })
          .catch((e) => {
            this.$buefy.toast.open({
              duration: 2000,
              message: "Ocurrió un error al generar el informe.",
              type: "is-danger",
            });
            this.$log.error(e);
          })
          .finally(() => {
            this.generating = false;
          });
      },
    },
  };
</script>

<style scoped></style>
