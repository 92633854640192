<script>
  import ViewNonCovidSampleModal from "@/components/samples/ViewNonCovidSampleModal.vue";
  import { formatDateTime } from "@/utils";

  export default {
    name: "NonCovidSamplesList",
    props: {
      samples: {
        type: Array,
        required: true,
      },
      searching: {
        type: Boolean,
        required: true,
      },
    },
    methods: {
      formatDateTime,
      formatStatus(sample) {
        if (sample.conclusion === "RECHAZADO") {
          return "Rechazado";
        } else {
          return sample.status;
        }
      },
      showSampleInfo(sampleId) {
        this.$buefy.modal.open({
          parent: this,
          component: ViewNonCovidSampleModal,
          hasModalCard: true,
          props: {
            sampleId,
          },
          events: {
            close: () => {
              this.searchSamples();
            },
          },
        });
      },
    },
  };
</script>

<template>
  <b-table :loading="searching" :data="samples">
    <b-table-column
      v-slot="props"
      field="sampleId"
      label="Muestra"
      cell-class="valign-mid"
      centered
      numeric
    >
      {{ props.row.sampleId }}
    </b-table-column>
    <b-table-column
      v-slot="props"
      field="idString"
      label="RUN"
      cell-class="valign-mid"
      centered
    >
      {{ props.row.idString }}
    </b-table-column>
    <b-table-column
      v-slot="props"
      field="institution"
      label="Institución"
      cell-class="valign-mid"
      centered
      numeric
    >
      {{ props.row.institution }}
    </b-table-column>
    <b-table-column
      v-slot="props"
      field="sampleCategory"
      label="Categoría"
      cell-class="valign-mid"
      centered
      numeric
    >
      {{ props.row.sampleCategory }}
    </b-table-column>
    <b-table-column
      v-slot="props"
      field="examTypeShort"
      label="Examen"
      cell-class="valign-mid"
      centered
      numeric
    >
      {{ props.row.examTypeShort }}
    </b-table-column>
    <b-table-column
      v-slot="props"
      field="receptionDatetime"
      label="Recepción"
      cell-class="valign-mid"
      centered
    >
      {{ formatDateTime(props.row.receptionDatetime) }}
    </b-table-column>
    <b-table-column
      v-slot="props"
      field="processingDatetime"
      label="Procesamiento"
      cell-class="valign-mid"
      centered
    >
      {{ formatDateTime(props.row.processingDatetime) }}
    </b-table-column>
    <b-table-column
      v-slot="props"
      field="status"
      label="Estado"
      cell-class="valign-mid"
      centered
    >
      {{ formatStatus(props.row) }}
    </b-table-column>
    <b-table-column v-slot="props" cell-class="valign-mid" centered>
      <b-tooltip type="is-light" label="Ver Información">
        <b-button
          type="is-primary"
          size="is-small"
          @click="showSampleInfo(props.row.sampleId)"
        >
          <b-icon icon="eye" custom-size="mdi-18px" />
        </b-button>
      </b-tooltip>
    </b-table-column>
  </b-table>
</template>
