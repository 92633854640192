<script>
  import MyTabItem from "@/components/menus/MyTabItem.vue";

  export default {
    name: "SearchNonCovid",
    components: { MyTabItem },
    created() {
      if (this.$route.name === "SearchNonCovidSamples") {
        this.$router.push({ name: "SearchNonCovidSamplesByText" });
      }
    },
  };
</script>

<template>
  <div class="container mt-5">
    <section>
      <div class="columns is-centered">
        <div class="column is-9">
          <h1 class="title">Búsqueda de Muestras (no COVID-19)</h1>
        </div>
      </div>
    </section>
    <section>
      <div class="columns is-centered">
        <div class="column is-9">
          <div class="tabs">
            <ul>
              <my-tab-item
                text="Por texto"
                route-name="SearchNonCovidSamplesByText"
              />
              <my-tab-item
                text="Por fecha"
                route-name="SearchNonCovidSamplesByDate"
              />
            </ul>
          </div>
        </div>
      </div>
    </section>
    <router-view />
  </div>
</template>

<style scoped></style>
