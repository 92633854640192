<template>
  <b-collapse animation="slide" :open="false">
    <b-button
      slot="trigger"
      slot-scope="props"
      type="is-light"
      :icon-right="props.open ? 'menu-up' : 'menu-down'"
    >
      Legenda
    </b-button>
    <div class="notification is-white">
      <div class="content">
        <div class="columns is-multiline">
          <div
            v-for="institution in institutions"
            :key="institution.name"
            class="column is-4 py-1"
          >
            <div class="level">
              <div class="level-left">
                <div
                  class="legend-block mr-2"
                  :style="getBoxBackground(institution.labelColor)"
                />
                [{{ institution.prefix }}] {{ institution.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-collapse>
</template>

<script>
  export default {
    name: "LabelLegend",
    props: {
      institutions: {
        type: Array,
        required: true,
      },
    },
    methods: {
      getBoxBackground(color) {
        return `background-color: #${color};`;
      },
    },
  };
</script>

<style scoped>
  div.legend-block {
    width: 36px;
    height: 18px;
    display: inline-block;
  }
</style>
