<template>
  <div>
    <b-message
      v-for="(message, i) in statusMessages"
      :key="i"
      :type="messageClasses[message.status]"
      class="my-1 tight-message"
    >
      <span>
        <strong>{{ message.origin }}: {{ message.status }}</strong>
      </span>
      <br />
      <template v-if="message.message">
        <span>Detalles:</span><br />
        <template v-if="Array.isArray(message.message)">
          <p v-for="(m, j) in message.message" :key="j">
            {{ m.sample }}: {{ m.error }}
          </p>
        </template>
        <template v-else>
          {{ message.message }}
        </template>
      </template>
    </b-message>
  </div>
</template>

<script>
  export default {
    name: "MessagesDisplay",
    props: {
      statusMessages: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        messageClasses: {
          OK: "is-success",
          error: "is-danger",
          warning: "is-warning",
        },
      };
    },
  };
</script>

<style>
  .tight-message > .message-body {
    padding: 0.5em 1em;
  }
</style>
