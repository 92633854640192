<template>
  <div>
    <h2 class="subtitle mb-2">No Validadas</h2>
    <p class="has-text-grey mb-4">
      Pools a las que aún no tienen ninguna validación asociada.
    </p>

    <pool-list :endpoint="route" :enable-deletion="true" />
  </div>
</template>

<script>
  import PoolList from "@/components/pools/PoolList.vue";
  import { routes } from "@/api";
  export default {
    name: "ListRecentPools",
    components: { PoolList },
    data() {
      return {
        route: routes.unvalidatedPools,
      };
    },
  };
</script>

<style scoped></style>
