import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import store from "@/store";
import router from "@/router";
import { routes } from "@/api";

let config = {
  baseURL:
    "production" === "production"
      ? "https://covid19.catg.cl/api"
      : "http://localhost:8000/",
  // timeout: 60 * 1000, // Timeout
  withCredentials: true, // Check cross-site Access-Control
  credentials: "same-origin",
};

const _axios = axios.create(config);

function forceLogout() {
  store.dispatch("logout").then(() => {
    sendToLogin();
  });
}

function sendToLogin() {
  router.push({ name: "Login" }).catch(() => {});
}

function handleError(error) {
  try {
    const status = error.response.status;
    const currentRoute = router.currentRoute.name;
    if (
      [403, 400].includes(status) &&
      !(currentRoute === "Login" && error.config.url === routes.logout)
    ) {
      forceLogout();
    }
  } catch {
    sendToLogin();
  }
  return Promise.reject(error);
}

_axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  return config;
}, handleError);

// Add a response interceptor
_axios.interceptors.response.use(function (response) {
  // Do something with response data
  return response;
}, handleError);

Vue.use(VueAxios, _axios);
