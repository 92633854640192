<template>
  <form @submit.prevent>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Rechazo de Muestra</p>
      </header>
      <section class="modal-card-body">
        <p class="mb-3">
          <strong> Se rechazará la siguiente muestra:</strong><br /><br />
          ID: {{ sample.sampleId }}<br />
          Institución: {{ sample.institution }}<br />
          Paciente: {{ sample.names }} {{ sample.firstLastname }}
          {{ sample.secondLastname }}<br />
          Tipo de examen: {{ sample.examType }}
        </p>
        <b-field label="Motivo">
          <b-select v-model="selectedOption" expanded required>
            <option
              v-for="rejectOption in rejectionOptions"
              :key="rejectOption.id"
              :value="rejectOption.id"
            >
              {{ rejectOption.name }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Comentarios">
          <b-input v-model="observations" :maxlength="128" type="textarea" />
        </b-field>

        <messages-display v-if="hasFailed" :status-messages="statusMessages" />
      </section>
      <footer class="modal-card-foot">
        <b-button :disabled="saving" @click="$parent.close()">
          Cancelar
        </b-button>
        <b-button
          type="is-danger"
          :loading="saving"
          :disabled="selectedOption === null"
          @click="rejectSample"
        >
          Rechazar Muestra
        </b-button>
      </footer>
    </div>
  </form>
</template>

<script>
  import { routes } from "@/api";
  import MessagesDisplay from "@/components/MessagesDisplay.vue";

  export default {
    name: "RejectNonCovidSampleModal",
    components: { MessagesDisplay },
    props: {
      sample: {
        type: Object,
        required: true,
      },
      rejectionOptions: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        observations: "",
        selectedOption: null,
        statusMessages: [],
        saving: false,
      };
    },
    computed: {
      hasFailed() {
        return this.statusMessages.some((x) => x.status !== "OK");
      },
    },
    methods: {
      rejectSample() {
        const data = {
          samples: [
            {
              sample_id: this.sample.sampleId,
              rejection_reason_id: this.selectedOption,
              details: this.observations,
            },
          ],
        };

        this.axios.post(routes.rejectNonCovidSamples, data).then(() => {
          this.$buefy.toast.open({
            message: "Muestra rechazada exitosamente",
            type: "is-success",
          });
          this.$emit("saved", this.$parent);
        });
      },
    },
  };
</script>

<style scoped></style>
