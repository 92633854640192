<template>
  <div>
    <qpcr-line-plot
      :raw-values="filteredRawValues"
      :targets="targets"
      :target-names="targetNames"
      :target-value="defaultCutoff"
      @update-cutoff="updateCq"
    />
    <hr />
    <qpcr-sample-table
      :sample-data="metadata"
      :target-names="targetNames"
      :grid="grid"
      :selected-samples.sync="selectedSamples"
    />
  </div>
</template>

<script>
  import QpcrSampleTable from "@/components/qpcr-analysis/QpcrSampleTable.vue";
  import QpcrLinePlot from "@/components/plots/QpcrLinePlot.vue";

  export default {
    name: "QpcrAnalysis",
    components: {
      QpcrLinePlot,
      QpcrSampleTable,
    },
    props: {
      rawValues: {
        type: Object,
        required: true,
      },
      metadata: {
        type: Array,
        required: true,
      },
      grid: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        selectedSamples: [],
      };
    },
    computed: {
      filteredRawValues() {
        return this.selectedSamples.reduce(
          (obj, key) => ({ ...obj, [key]: this.rawValues[key] }),
          {}
        );
      },
      targets() {
        return this.metadata.reduce((o, v) => {
          if (!o[v.reporter]) {
            o[v.reporter] = [];
          }
          if (!o[v.reporter].includes(v.targetName)) {
            o[v.reporter].push(v.targetName);
          }
          return o;
        }, {});
      },
      targetNames() {
        return this.metadata.reduce((o, v) => {
          if (!o[v.sample]) {
            o[v.sample] = {};
          }
          if (!o[v.sample][v.reporter]) {
            o[v.sample][v.reporter] = v.targetName;
          }
          return o;
        }, {});
      },
      defaultCutoff() {
        const famAvg = Math.max.apply(
          Math,
          Object.values(this.rawValues).map((x) => this.mean(x.FAM))
        );

        return famAvg / 2 ? famAvg / 2 : 300;
      },
    },
    watch: {
      rawValues() {
        this.selectedSamples = [];
      },
    },
    methods: {
      updateCq(channel, reporter, cutoff) {
        for (let sample of this.metadata) {
          if (sample.targetName === channel) {
            const values = this.rawValues[sample.sample][reporter];
            // if it's lower than minimun value or higher than maximun, then set value as null
            if (cutoff < values[0] || cutoff > values[values.length - 1]) {
              sample.cq = null;
            } else {
              const i = values.findIndex((x) => x > cutoff);
              sample.cq = (
                i +
                1 -
                (values[i] - cutoff) / (values[i] - values[i - 1])
              ).toFixed(2);
            }
          }
          sample.cqMean = sample.cq;
        }

        this.$emit("update-cutoff", channel, cutoff);
      },
      mean(arr) {
        if (arr.length > 0) {
          return arr.reduce((s, x) => (!isNaN(x) ? s + x : s), 0) / arr.length;
        } else {
          return NaN;
        }
      },
    },
  };
</script>

<style scoped></style>
