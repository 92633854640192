<template>
  <div class="table-container">
    <b-table v-if="tableData" :data="tableData" :loading="loading" bordered>
      <b-table-column
        v-slot="{ row }"
        :label="indexColumn.label"
        :field="indexColumn.field"
        :centered="false"
        cell-class="valign-mid"
      >
        <span class="has-text-weight-bold">{{ row[indexColumn.field] }}</span>
      </b-table-column>
      <b-table-column
        v-for="column in plateColumns"
        :key="column.field"
        v-slot="{ row }"
        :label="column.label"
        :field="column.field"
        :centered="column.centered"
        cell-class="valign-mid"
      >
        <b-tag v-if="isSample(row[column.field])" type="is-success">
          {{ row[column.field] }}</b-tag
        >
        <span v-else-if="row[column.field]"> {{ row[column.field] }} </span>
        <span v-else>-</span>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
  export default {
    name: "PlateTable",
    props: {
      tableData: {
        type: Array,
        default: null,
      },
    },
    data() {
      return {
        loading: true,
        indexColumn: {
          field: "index",
          label: "",
          width: "40",
          centered: false,
        },
        plateColumns: [],
      };
    },
    computed: {
      samples() {
        if (this.tableData) {
          return this.tableData
            .map((x) => Object.values(x))
            .flat()
            .filter((x) => typeof x === "number");
        } else {
          return [];
        }
      },
    },
    created() {
      this.loading = true;
      // create 12 columns for table data
      for (let i = 1; i < 13; i++) {
        this.plateColumns.push({
          field: `${i}`,
          label: `${i}`,
          width: "40",
          centered: true,
        });
      }
      this.loading = false;
    },
    methods: {
      isSample(sample) {
        return this.samples.includes(sample);
      },
    },
  };
</script>

<style scoped></style>
