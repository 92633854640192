<template>
  <b-navbar-dropdown v-if="hasPermission" :label="label">
    <template v-for="(child, index) in authorizedChildren">
      <my-navbar-item
        v-if="child.route"
        :key="index"
        :route-name="child.route"
        :route-params="child.routeParams"
        :label="child.label"
      />
      <hr v-else :key="index" class="dropdown-divider" />
    </template>
  </b-navbar-dropdown>
</template>

<script>
  import MyNavbarItem from "@/components/menus/MyNavbarItem.vue";
  import { hasPermission } from "@/utils";
  export default {
    name: "MyNavbarDropdown",
    components: { MyNavbarItem },
    props: {
      children: {
        type: Array[Object],
        required: true,
      },
      label: {
        type: String,
        required: true,
      },
    },
    computed: {
      hasPermission() {
        return this.children
          .filter((x) => !x.divider)
          .some((x) => {
            return hasPermission(x.route, x.routeParams);
          });
      },
      authorizedChildren() {
        return this.children
          .filter((x) => hasPermission(x.route, x.routeParams))
          .filter((x, idx, arr) => {
            return (
              x.route ||
              (idx !== 0 && idx !== arr.length - 1 && arr[idx - 1].route)
            );
          });
      },
    },
  };
</script>

<style scoped></style>
