import SampleBatches from "@/views/batches/SampleBatches.vue";
import BatchDetails from "@/views/batches/BatchDetails.vue";
import BatchManagement from "@/views/batches/BatchManagement.vue";

const routes = [
  {
    path: "/samples/batches",
    name: "SampleBatches",
    component: SampleBatches,
    meta: { authorized: ["sample-manager", "dispatcher"] },
  },
  {
    path: "/samples/batches/:id/details",
    name: "BatchDetails",
    component: BatchDetails,
    meta: { authorized: ["sample-manager", "dispatcher"] },
  },
  {
    path: "/samples/batches/:id/management",
    name: "BatchManagement",
    component: BatchManagement,
    meta: { authorized: ["sample-manager", "dispatcher"] },
  },
];

export default routes;
