<template>
  <div class="table-container">
    <b-table
      v-if="tableData.length > 0"
      :data="tableData"
      :show-header="false"
      bordered
    >
      <b-table-column
        v-for="column in plateColumns"
        :key="column.field"
        v-slot="{ row }"
        :label="column.label"
        :field="column.field"
        :centered="column.centered"
        cell-class="valign-mid"
      >
        <template v-if="row[column.field] && row[column.field] !== 'empty'">
          <b-tag type="is-success"> {{ row[column.field].sample }}</b-tag>
          <b-tag type="is-info">bc:{{ row[column.field].barcode }}</b-tag>
        </template>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
  export default {
    name: "SampleSequencingTable",
    props: {
      tableData: {
        type: Array,
        default: null,
      },
    },
    data() {
      return {
        indexColumn: {
          field: "index",
          label: "",
          width: "40",
          centered: false,
        },
      };
    },
    computed: {
      samples() {
        if (this.tableData) {
          return this.tableData
            .map((x) => Object.values(x))
            .flat()
            .filter((x) => typeof x === "number");
        } else {
          return [];
        }
      },
      plateColumns() {
        if (this.tableData.length === 0) {
          return [];
        }

        const ncols = Math.min(12, Object.keys(this.tableData[0]).length);
        return Array.from(Array(ncols).keys()).map((i) => ({
          field: `${i}`,
          label: `${i}`,
          width: "40",
          centered: true,
        }));
      },
    },
    methods: {
      isSample(sample) {
        return this.samples.includes(sample);
      },
    },
  };
</script>

<style scoped></style>
