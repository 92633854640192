import {
  alpha,
  alpha_spaces,
  email,
  integer,
  oneOf,
  regex,
  required,
  alpha_num,
  size,
  is_not,
  max,
  min,
} from "vee-validate/dist/rules";
import { extend } from "vee-validate";

extend("required", {
  ...required,
  message: "Este campo es requerido",
});

extend("regex", {
  ...regex,
  message: "Este campo no cumple con el formato requerido",
});

extend("integer", {
  ...integer,
  message: "Este campo debe ser numérico",
});

extend("alpha_spaces", {
  ...alpha_spaces,
  message: "Este campo solo puede contener letras y espacios",
});

extend("alpha", {
  ...alpha,
  message: "Este campo solo puede contener letras",
});

extend("email", {
  ...email,
  message: "Este campo debe ser un e-mail válido",
});

extend("oneOf", {
  ...oneOf,
  message: "Debe seleccionar una opción válida",
});

extend("contactData", {
  ...required,
  message: "Debe completar al menos un dato de contacto",
});

extend("alpha_num", {
  ...alpha_num,
  message: "Este campo solo puede tener letras o números",
});

extend("fileSize", {
  ...size,
  message: "Su archivo supera el tamaño maximo permitido",
});

extend("distinct", {
  ...is_not,
  message: "Estos campos no pueden ser iguales",
});

extend("min", {
  ...min,
  message: "Este campo no puede tener menos de {length} caracteres",
});

extend("max", {
  ...max,
  message: "Este campo no puede superar {length} caracteres",
});

extend("positive", {
  validate: (value) => value > 0,
  message: "Este campo debe ser positivo",
});
