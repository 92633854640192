import DataInput from "@/views/samples/PcrDataInput.vue";
import SamplesInput from "@/views/samples/SamplesInput.vue";
import ListReceivedSamplesSSM from "@/views/samples/ListReceivedSamplesSSM.vue";
import SampleClosure from "@/views/samples/SampleClosure.vue";
import LabelPrinting from "@/views/samples/LabelPrinting.vue";
import ResultValidation from "@/views/samples/ResultValidation.vue";
import UnreportedSamples from "@/views/samples/UnreportedSamples.vue";
import UnreportedSamplesSSM from "@/views/samples/UnreportedSamplesSSM.vue";
import ResultsReview from "@/views/samples/ResultsReview.vue";
import PendingSamples from "@/views/samples/PendingSamples.vue";
import PendingNonCovidSamples from "@/views/samples/PendingNonCovidSamples.vue";
import SearchNonCovid from "@/views/samples/SearchNonCovid.vue";
import SearchNonCovidByText from "@/views/samples/SearchNonCovidByText.vue";
import SearchNonCovidByDate from "@/views/samples/SearchNonCovidByDate.vue";

const routes = [
  {
    path: "/input/pcr-results",
    name: "DataInput",
    component: DataInput,
    meta: { authorized: ["pcr-analyst"] },
  },
  {
    path: "/input/samples",
    name: "SamplesInput",
    component: SamplesInput,
    meta: { authorized: ["sample-manager"] },
  },
  {
    path: "/input/samples-ssm",
    name: "ReceivedSamplesSSM",
    component: ListReceivedSamplesSSM,
    meta: { authorized: ["sample-manager"] },
  },
  {
    path: "/samples/closure",
    name: "SampleClosure",
    component: SampleClosure,
    meta: { authorized: ["lab-manager"] },
  },
  {
    path: "/samples/label-printing",
    name: "LabelPrinting",
    component: LabelPrinting,
    meta: { authorized: ["sample-manager"] },
  },
  {
    path: "/samples/pcr-validation",
    name: "ResultValidation",
    component: ResultValidation,
    meta: { authorized: ["pcr-analyst"] },
  },
  {
    path: "/reporting/unreported-samples",
    name: "UnreportedSamples",
    component: UnreportedSamples,
    meta: { authorized: ["pcr-analyst"] },
  },
  {
    path: "/reporting/unreported-samples-ssm",
    name: "UnreportedSamplesSSM",
    component: UnreportedSamplesSSM,
    meta: { authorized: ["pcr-analyst"] },
  },
  {
    path: "/results/review/",
    name: "ResultsReview",
    component: ResultsReview,
    meta: { authorized: ["extr-analyst", "pcr-analyst"] },
  },
  {
    path: "/samples/pending/:status",
    name: "PendingSamples",
    component: PendingSamples,
    meta: {
      authorized: [
        "sample-manager",
        "extr-analyst",
        "pcr-analyst",
        "specialist",
      ],
    },
  },
  {
    path: "/samples/pending-noncovid",
    name: "PendingNonCovidSamples",
    component: PendingNonCovidSamples,
    meta: {
      authorized: ["sample-manager", "extr-analyst", "pcr-analyst"],
    },
  },
  {
    path: "/samples/search-noncovid",
    name: "SearchNonCovidSamples",
    component: SearchNonCovid,
    meta: {},
    children: [
      {
        path: "/samples/search-noncovid/by-text",
        name: "SearchNonCovidSamplesByText",
        component: SearchNonCovidByText,
        meta: {
          authorized: ["sample-manager"],
        },
      },
      {
        path: "/samples/search-noncovid/by-date",
        name: "SearchNonCovidSamplesByDate",
        component: SearchNonCovidByDate,
        meta: {
          authorized: ["sample-manager"],
        },
      },
    ],
  },
];

export default routes;
