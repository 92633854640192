<template>
  <div class="table-container">
    <b-table
      v-if="samples.length > 0 || loading"
      :data="filteredSamples"
      :checked-rows.sync="selectedSamples"
      :loading="loading"
      checkable
    >
      <template v-for="column in columns">
        <b-table-column v-slot="props" :key="column.id" v-bind="column">
          <template
            v-if="
              column.field === 'institution' && availableInstitutions.length > 1
            "
            slot="subheading"
          >
            <b-select
              v-model="selectedInstitution"
              placeholder="Seleccionar institución"
              expanded
            >
              <option
                v-for="option in availableInstitutions"
                :key="option"
                :value="option"
              >
                {{ option }}
              </option>
            </b-select>
          </template>
          {{ props.row[column.field] }}
        </b-table-column>
      </template>
      <template slot="bottom-left">
        <b>Seleccionadas</b>: {{ selectedSamples.length }}
      </template>

      <template slot="empty"><div class="table-empty" /></template>
    </b-table>
    <div v-else>
      <b-message> No hay muestras disponibles para armar pools. </b-message>
    </div>
  </div>
</template>

<script>
  import { routes } from "@/api";
  import { formatDateTime } from "@/utils";

  export default {
    name: "SampleListForPooling",
    data() {
      return {
        samples: [],
        columns: [
          {
            field: "sampleId",
            label: "N° Muestra",
            centered: true,
            sortable: true,
          },
          {
            field: "institution",
            label: "Institución",
            centered: true,
          },
          { field: "externalId", label: "N° Pet. (Ex)", centered: true },
          { field: "idString", label: "RUN / Pas.", centered: true },
          {
            field: "receptionDatetime",
            label: "Ingreso",
            centered: true,
            sortable: true,
          },
        ],
        selectedSamples: [],
        selectedInstitution: null,
        loading: false,
      };
    },
    computed: {
      availableInstitutions() {
        return [...new Set(this.samples.map((x) => x.institution))];
      },
      filteredSamples() {
        if (!this.selectedInstitution) {
          return this.samples;
        }
        return this.samples.filter(
          (x) => x.institution === this.selectedInstitution
        );
      },
    },
    watch: {
      selectedSamples(val) {
        this.$emit(
          "update-selection",
          val.map((x) => x.sampleId)
        );
      },
    },
    created() {
      this.loadData();
    },
    methods: {
      loadData() {
        this.loading = true;
        this.axios
          .get(routes.samplesForPooling)
          .then((response) => {
            this.samples = response.data.results
              .filter((x) => !x.repetition)
              .map((x) => ({
                ...x,
                receptionDatetime: formatDateTime(x.receptionDatetime),
              }));
          })
          .catch((e) => {
            this.$log.error(e);
          })
          .finally(() => {
            this.loading = false;
          });
      },
    },
  };
</script>

<style scoped></style>
