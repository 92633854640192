<template>
  <div class="container mt-5">
    <b-loading :active="loading" :is-full-page="false" />
    <user-form
      title="Modificar Usuario"
      :user="user"
      :saving="saving"
      @submit="saveUser"
    />
  </div>
</template>

<script>
  import UserForm from "@/components/auth/UserForm.vue";
  import { routes } from "@/api";
  import snakecaseKeys from "snakecase-keys";
  import camelcaseKeys from "camelcase-keys";

  export default {
    name: "EditUser",
    components: {
      UserForm,
    },
    data() {
      return {
        user: {},
        saving: false,
        loading: false,
      };
    },
    created() {
      this.getUser();
    },
    methods: {
      async getUser() {
        this.loading = true;
        const response = await this.axios.get(
          routes.getUser.replace(":id", this.$route.params.id)
        );
        this.user = camelcaseKeys(response.data.result);
        this.loading = false;
      },
      async saveUser(userData) {
        this.saving = true;
        const response = await this.axios.post(
          routes.updateUser.replace(":id", this.user.id),
          snakecaseKeys({
            ...userData,
            tokenExpiration: parseInt(userData.tokenExpiration),
          })
        );

        if (response.data.status === "OK") {
          this.$buefy.toast.open({
            duration: 2000,
            message: "Usuario actualizado correctamente",
            type: "is-success",
          });
          await this.$router.push({ name: "ListUsers" });
        } else {
          this.$buefy.dialog.alert({
            title: "Error",
            message: response.data.message,
            type: "is-danger",
            hasIcon: true,
          });
        }
        this.saving = false;
      },
    },
  };
</script>

<style scoped></style>
