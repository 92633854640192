<template>
  <div class="columns">
    <div class="column is-half is-offset-one-quarter">
      <minsal-report
        title="Consolidado"
        api-route="getConsolidated"
        :password-protect-option="true"
      />
      <hr />
      <minsal-report
        title="Reporte Estadístico"
        api-route="getStatisticalReport"
        name-prefix="CADIUMAG_ReporteEstadistico_"
      />
    </div>
  </div>
</template>

<script>
  import MinsalReport from "@/views/reports/MinsalReport.vue";

  export default {
    name: "MinsalReports",
    components: { MinsalReport },
  };
</script>

<style scoped></style>
