<template>
  <section class="hero is-light is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-5-tablet is-4-desktop is-3-widescreen">
            <div class="box">
              <div class="level is-mobile">
                <div class="level-item has-text-centered">
                  <img
                    src="@/assets/logo_catg.svg"
                    alt="CATG logo"
                    class="logo"
                  />
                </div>
                <div class="level-item has-text-centered">
                  <img
                    src="@/assets/logo_umag_blue.svg"
                    alt="UMAG logo"
                    class="logo"
                  />
                </div>
              </div>
              <div class="has-text-centered">
                <span class="title is-4">SISTEMA COVID-19</span>
              </div>
              <hr />
              <form @submit.prevent>
                <b-field label="Usuario">
                  <b-input v-model="username" icon="account" required></b-input>
                </b-field>
                <b-field label="Contraseña">
                  <b-input
                    v-model="password"
                    icon="key"
                    type="password"
                    password-reveal
                    required
                  >
                  </b-input>
                </b-field>
                <p class="has-text-centered mb-4">
                  <span class="has-text-danger">{{ error }}</span>
                </p>
                <b-button
                  type="is-primary"
                  native-type="submit"
                  expanded
                  :loading="loading"
                  @click="login"
                >
                  Iniciar Sesión
                </b-button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: "Login",
    data() {
      return {
        username: null,
        password: null,
        error: null,
        loading: false,
      };
    },
    created() {
      this.$store.dispatch("logout");
    },
    methods: {
      login() {
        this.error = null;
        this.loading = true;
        this.$store
          .dispatch("login", {
            username: this.username,
            password: this.password,
          })
          .then(() => {
            this.$router.push({ name: "Home" });
          })
          .catch((e) => {
            this.error = e.response.data;
          })
          .finally(() => {
            this.loading = false;
          });
      },
    },
  };
</script>

<style scoped>
  .logo {
    height: 100px;
    width: auto;
  }
  .hero {
    /* by default leaves some kind of margin in the bottom... */
    margin-bottom: -35px;
  }
</style>
