<template>
  <div>
    <chartjs-line-plot
      :chart-data="cummulativeCasesData"
      :y-scale-log="logscale"
      title="Muestras acumuladas"
    />
    <div class="level">
      <div class="level-item">
        <p class="control">
          <b-switch v-model="logscale" :rounded="false">
            Escala Logarítmica
          </b-switch>
        </p>
        <p class="control ml-4">
          <b-tooltip type="is-light" label="Descargar imagen">
            <b-button
              type="is-primary"
              size="is-small"
              outlined
              @click="exportToImage"
            >
              <b-icon icon="download" custom-size="mdi-18px" />
            </b-button>
          </b-tooltip>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import ChartjsLinePlot from "@/components/plots/ChartjsLinePlot.vue";

  export default {
    name: "CummulativeCasesLineplot",
    components: { ChartjsLinePlot },
    props: {
      data: {
        required: true,
        type: Object,
      },
    },
    data() {
      return {
        logscale: false,
        colors: {
          Positivo: "rgba(255,88,88,0.9)",
          "No Concluyente": "rgba(255,176,85,0.9)",
          Negativo: "rgba(218,218,218,0.9)",
        },
      };
    },
    computed: {
      cummulativeCasesData() {
        return {
          labels: this.$props.data.date
            ? this.$props.data.date.map((x) =>
                this.$date(x, "YYYY-MM-DD").format("DD-MM-YY")
              )
            : [],
          datasets: Object.keys(this.colors).map((x) => {
            return this.$props.data.Positivo
              ? {
                  label: x,
                  backgroundColor: this.colors[x],
                  data: this.data[x].map(
                    (
                      (sum) => (value) =>
                        (sum += value)
                    )(0)
                  ),
                }
              : [];
          }),
        };
      },
    },
    methods: {
      exportToImage() {
        const canvas = document
          .getElementById("line-chart")
          .toDataURL("image/png");
        let link = document.createElement("a");
        link.download = `cumulative_samples_${this.$date().format(
          "YYYYMMDD_HHmm"
        )}.png`;
        link.href = canvas;
        link.click();
      },
    },
  };
</script>

<style scoped></style>
