import router from "@/router";
import store from "@/store";
import dayjs from "dayjs";

function hasPermission(routeName, params = {}) {
  const route = router.resolve({ name: routeName, params: params }).route;
  const { roles } = store.state.user;
  const adminRole = "__admin";

  if (route.meta.authorized && !roles.includes(adminRole)) {
    return roles && roles.some((x) => route.meta.authorized.includes(x));
  }

  return true;
}

function formatDateTime(datetime, includeYear = false) {
  try {
    if (datetime !== "" && datetime !== null && datetime !== undefined) {
      const datetime_dayjs = dayjs(datetime);

      if (datetime_dayjs.isValid()) {
        return datetime_dayjs.format(`DD-MM${includeYear ? "-YY" : ""} HH:mm`);
      } else {
        return "-";
      }
    } else {
      return "-";
    }
  } catch {
    return "-";
  }
}

function validate(target, value, allCutoffs) {
  const cutoffs = allCutoffs[target.split("_")[0]];

  if (isNaN(value) || value == null) {
    return false;
  } else {
    if (cutoffs.lower) {
      return cutoffs.lower < value < cutoffs.upper;
    } else {
      return value < cutoffs.upper;
    }
  }
}

function random() {
  const cryptoArray = new Uint32Array(1);
  window.crypto.getRandomValues(cryptoArray);
  return cryptoArray[0] / 0xffffffff;
}

function generatePassword(
  length = 12,
  charsetsUsed = ["lower", "upper", "numbers", "symbols"]
) {
  const charSetsAvalible = {
    lower: "abcdefghijklmnopqrstuvwxyz",
    upper: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
    numbers: "0123456789",
    symbols: "!@#$%*_:;./",
  };
  const charSets = charsetsUsed.map((charSet) => charSetsAvalible[charSet]);
  let password = "";
  while (password.length < length) {
    const charsetNum = Math.floor(charSets.length * random());
    const currentCharset = charSets[charsetNum];
    const charNum = Math.floor(random() * currentCharset.length);
    password += currentCharset.charAt(charNum);
  }
  return password;
}

const datepickerOptions = {
  monthNames: [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ],
  dayNames: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
  firstDayOfWeek: 1,
};

export {
  hasPermission,
  formatDateTime,
  validate,
  generatePassword,
  datepickerOptions,
};
