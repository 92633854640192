<template>
  <validation-provider
    v-slot="{ errors, valid, validated }"
    :vid="vid"
    :name="$attrs.name || $attrs.label"
    :rules="rules"
    :debounce="debounce"
  >
    <b-field
      v-bind="$attrs"
      :type="{ 'is-danger': errors[0], 'is-success': valid && validated }"
      :message="errors"
      :label="$attrs.label"
    >
      <slot name="addon-left"></slot>
      <b-input
        v-model="innerValue"
        v-bind="$attrs"
        :placeholder="$props.placeholder"
      />
      <slot name="addon-right"></slot>
    </b-field>
  </validation-provider>
</template>

<script>
  import { ValidationProvider } from "vee-validate";

  export default {
    components: {
      ValidationProvider,
    },
    props: {
      vid: {
        type: String,
        default: null,
      },
      debounce: {
        type: Number,
        default: 0,
      },
      rules: {
        type: [Object, String],
        default: "",
      },
      value: {
        type: null,
        default: null,
      },
      placeholder: {
        type: String,
        default: null,
      },
    },
    data: () => ({
      innerValue: "",
    }),
    watch: {
      // Handles internal model changes.
      innerValue(newVal) {
        this.$emit("input", newVal);
      },
      // Handles external model changes.
      value(newVal) {
        this.innerValue = newVal;
      },
    },
    created() {
      if (this.value) {
        this.innerValue = this.value;
      }
    },
  };
</script>
