<template>
  <section>
    <div class="columns is-centered">
      <div class="column is-9">
        <h1 class="subtitle">Informes Positivos</h1>
        <p class="has-text-grey">
          Esta opción generará un archivo comprimido con los informes de
          muestras con resultado positivo en el rango de fechas / horas
          indicado.
        </p>
      </div>
    </div>
    <div class="columns">
      <div class="column is-half is-offset-one-quarter">
        <div>
          <b-field label="Desde">
            <b-datetimepicker
              v-model="datetimeFrom"
              placeholder="Seleccione fecha / hora..."
              icon="calendar-today"
              :datepicker="datepickerOptions"
              :timepicker="{ hourFormat: '24' }"
            />
          </b-field>
          <b-field label="Hasta">
            <b-datetimepicker
              v-model="datetimeTo"
              placeholder="Seleccione fecha / hora..."
              icon="calendar-today"
              :datepicker="datepickerOptions"
              :timepicker="{ hourFormat: '24' }"
            />
          </b-field>
          <div class="field">
            <span v-if="errorMessage" class="has-text-danger">
              {{ errorMessage }}
            </span>
          </div>
          <b-button type="is-primary" :loading="generating" @click="submit">
            Generar
          </b-button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import { routes } from "@/api";
  import { saveAs } from "file-saver";
  import { datepickerOptions } from "@/utils";

  export default {
    name: "PositiveReports",
    data() {
      return {
        datetimeFrom: this.$date()
          .add(-1, "day")
          .hour(process.env.VITE_DAILY_CUTOFF_TIME)
          .minute(0)
          .toDate(),
        datetimeTo: this.$date()
          .hour(process.env.VITE_DAILY_CUTOFF_TIME)
          .minute(0)
          .toDate(),
        errorMessage: "",
        generating: false,
        datepickerOptions,
      };
    },
    methods: {
      submit() {
        this.generating = true;
        this.errorMessage = "";

        this.axios
          .get(routes.getPositiveReports, {
            params: {
              datetime_from: this.$date(this.datetimeFrom).format(
                "YYYY-MM-DD HH:mm"
              ),
              datetime_to: this.$date(this.datetimeTo).format(
                "YYYY-MM-DD HH:mm"
              ),
            },
            responseType: "blob",
          })
          .then((response) => {
            this.generating = false;
            if (response.data.type === "application/json") {
              this.errorMessage =
                "No hay muestras positivas en el rango seleccionado.";
            } else {
              const blob = new Blob([response.data], {
                type: "application/zip",
              });
              saveAs(
                blob,
                `positive_reports_${this.$date(this.date).format(
                  "DDMMYYYY_HHmm"
                )}.zip`
              );
            }
          });
      },
    },
  };
</script>

<style scoped></style>
